import { svgIcons } from "./svgIcons";

type LoginBtnProps = {
  mode: number;
  signedIn: boolean;
  setShowLogin: any;
  setSigninMode: any;
  setSignupMode: any;
  user: string;
  fullPageHandler: any;
  addBtnLeft: boolean;
  showButton: boolean;
  showContent: boolean;
};

const LoginBtn2 = (props: LoginBtnProps) => {
  return (
    <div
      // className={
      //   props.addBtnLeft
      //     ? "fixed bottom-[10px] left-0"
      //     : "fixed bottom-[10px] right-0"
      // }
    >
      {props.signedIn && (
        <div
          className={
            props.showButton
              ? "flex justify-between items-center p-[6px] m-5 h-12 w-60 p-[16px] bg-[#2D4263] border-4 border-[#2D4263] rounded-full px-3 pl-4 transition-all ease-out duration-300"
              : "flex justify-between items-center fixed bottom-[10px] p-[6px] m-5 h-12 w-16 bg-[#2D4263] border-4 border-[#2D4263] rounded-full px-3 pl-4"
          }
          onClick={() => props.fullPageHandler("account")}
        >
          <p
            className={
              props.showContent
                ? "opacity-1 text-white text-lg text-center tracking-wide capitalize transition-all ease-out duration-500"
                : "opacity-0 text-white text-lg text-center tracking-wide capitalize"
            }
          >{`${props.user}`}</p>
          <div
            className={
              props.showContent
                ? "opacity-90 fill-white transition-all ease-out duration-500"
                : "opacity-0 fill-white"
            }
          >
            {svgIcons.settings}
          </div>
        </div>
      )}
      {!props.signedIn && props.user === "" && (
        <div
          className={
            props.showButton
              ? "p-[6px] m-5 h-12 w-60 bg-white/80 border-4 border-[#ffffff] rounded-full transition-all ease-out duration-300"
              : "p-[6px] m-5 h-12 w-12 bg-white/80 border-4 border-[#ffffff] rounded-full"
          }
          onClick={() => props.setSignupMode(true)}
        >
          <p
            className={
              props.showContent
                ? "opacity-1 text-[#2D4263] text-bold text-lg tracking-normal text-center transition-all ease-out duration-500"
                : "opacity-0 text-[#2D4263] text-bold text-lg tracking-normal text-center"
            }
          >
            Create an Account / Login
          </p>
        </div>
      )}
      {!props.signedIn && props.user !== "" && (
        <div
          className={
            props.showButton
              ? "p-[6px] m-5 h-12 w-60 bg-white/80 border-4 border-[#2D4263] rounded-full transition-all ease-out duration-300"
              : "p-[6px] m-5 h-12 w-12 bg-white/80 border-4 border-[#2D4263] rounded-full"
          }
          onClick={() => props.setSigninMode(true)}
        >
          {/* <p className="text-[#2D4263] text-bold tracking-wide text-center">
            {`Hi ${props.user}!`}
          </p> */}
          <p
            className={
              props.showContent
                ? "opacity-1 text-[#2D4263] text-lg text-bold tracking-wide text-center transition-all ease-out duration-500"
                : "opacity-0 text-[#2D4263] text-lg text-bold tracking-wide text-center"
            }
          >
            {`Sign In`}
          </p>
        </div>
      )}
    </div>
  );
};

export default LoginBtn2;

{
  /* <span
  className={
    mode === 1
      ? "drop-shadow-none fill-white/50"
      : "hover:drop-shadow-sm fill-[#2D4263] hover:fill-[#C84B31]/80"
  }
  onClick={onAddHandler}
>
  {svgIcons.addIcon}
</span> */
}
