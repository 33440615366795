import { useEffect, useState } from "react";
import { Word } from "./utils/types";
import { svgIcons } from "./components/svgIcons";

interface BookListData {
  book: string;
  author: string;
  words: number;
  currentPage: string;
  startDate: string;
  endDate: string;
}

type BookListProps = {
  data: Word[];
  getLastEntryFromBook: any;
};

const sortList = ["A - Z", "Z - A", "Newest", "Oldest", "Longest", "Shortest"];

const BookList = (props: BookListProps) => {
  const [books, setBooks] = useState<BookListData[]>([]);
  const [sortType, setSortType] = useState(sortList[0]);
  const [bookDetailMode, setBookDetailMode] = useState(false);
  const [bookDetailContent, setBookDetailContent] = useState<BookListData>({
    book: "",
    author: "string",
    words: 0,
    currentPage: "",
    startDate: "",
    endDate: "",
  });
  const [bookDeleteMode, setBookDeleteMode] = useState(false);
  const [wordCount, setWordCount] = useState([{ title: "", count: 0, words: "0" }]);

  useEffect(() => {
    console.log("Using Effect");
    const bl = makeBookList(props.data);
    console.log(bl);
    setBooks(() => bl);
    getWordCount();
  }, []);

  const getWordCount = () => {
    const finalList: any = [];
    props.data.forEach((e) => {
      if (!finalList.some((b: { title: string }) => b.title === e.book)) {
        const book = { title: e.book, words: "", count: 1 };
        finalList.push(book);
      } else {
        let index = finalList.findIndex(
          (x: { title: any }) => x.title === e.book
        );
        finalList[index].count = finalList[index].count + 1;
        finalList[index].words = finalList[index].count.toString();
      }
      console.log("finallist", finalList);
    });
    setWordCount(() => finalList);
  };

  const parseWordCount = (book: string) => {
    var index = wordCount.findIndex((b) => b.title == book);
    return wordCount[index];
  };

  const makeBookList = (data: Word[]) => {
    console.log("data: ", data);
    const localBooks = data.filter((b) => b.sharedid === 0);
    console.log("local books...", localBooks);
    let wordCount = 0;
    console.log("Making booklist");
    const bookList: BookListData[] = [];
    let currentPage = "";
    // let wordCount = 0;
    localBooks.forEach(
      (
        d: { book: string; author: string; date: string; page: string },
        index: number
      ) => {
        if (d.book === "") {
          return;
        }
        var author = d.author;
        var startDate = "";
        var endDate = "current";
        // var currentPage = "temp";
        // let wordCount = 0;
        console.log("Booklist data: ", bookList);
        const i = bookList.findIndex((b) => b.book === d.book);
        console.log("what is i?", i);
        if (i !== -1) {
          console.log("found a match!");
          console.log(data[index].word, index);
          // currentPage = data[index].page;
          // ++wordCount;
          if (data[index].word === "end") {
            endDate = data[index].date;
            var endMonth = new Date(data[index].date).getMonth();
            var endYear = new Date(data[index].date).getFullYear();
            endDate = endMonth + "/" + endYear;
            console.log("found an end!");
            const n = bookList.findIndex((b) => b.book === d.book);
            console.log(bookList);
            bookList[n] = {
              book: d.book,
              author: author,
              words: wordCount,
              currentPage: currentPage,
              startDate: bookList[n].startDate,
              endDate: endDate,
            };
            console.log(bookList);
          }
        } else {
          var startDate = "";
          var startMonth = new Date(d.date).getMonth() + 1;
          var startYear = new Date(d.date).getFullYear();
          startDate = startMonth + "/" + startYear;
          console.log("Start Date: ", startDate);
          bookList.push({
            book: d.book,
            author: d.author,
            currentPage: currentPage,
            words: wordCount,
            startDate,
            endDate,
          });
        }
        // !bookList.includes(d.book) ? bookList.push({ book: d.book, startDate, endDate }) : null
      }
    );
    return bookList;
  };

  const sortChangeHandler = (e: { target: { value: string } }) => {
    console.log(e.target.value);
    setSortType(e.target.value);
    // setSortType(e.target.value);
  };

  const showWordDetail = (word: string) => {
    console.log(word);
  };

  const bookDeleteHandler = () => {
    console.log(`Deleting ${bookDetailMode}...`);
    closeBookDetail();
  };

  const clickBookHandler = (book: BookListData) => {
    setBookDetailMode(() => true);
    setBookDetailContent(() => book);
    console.log("book clicked...", book);
  };

  const closeBookDetail = () => {
    console.log(`Closing book detail...`);
    setBookDeleteMode(false);
    setBookDetailMode(false);
  };

  return (
    <>
      <div
        className={`bg-[#191919] absolute z-1000 p-4 pb-10 translate-y-0 left-0 w-full bottom-0 text-white rounded-t-lg md:rounded md:opacity-0 transition-all duration-300 ${
          bookDetailMode
            ? "translate-y-0 md:translate-y-0 md:opacity-100"
            : "translate-y-full md:translate-y-0 md:opacity-0"
        }`}
      >
        {parseWordCount(bookDetailContent!.book) && <div className="grid gap-1 grid-cols-1 pb-8 md:pb-0">
          <div className="flex w-full justify-between items-baseline">
            <p className="text-lg truncate text-nowrap w-3/4">{bookDetailContent?.book}</p>
            <p className="text-base w-1/4 text-right">{parseWordCount(bookDetailContent!.book).words === "" ? "" : `${
              parseWordCount(bookDetailContent!.book).words
            } words`}</p>
          </div>
          <div className="flex justify-between items-baseline">
            <p className="text-base">{`by ${bookDetailContent?.author}`}</p>
            {bookDetailContent?.endDate === "current" && (
              <p className="text-base">{`current page: ${
                props.getLastEntryFromBook(bookDetailContent.book).page
              }`}</p>
            )}
            {bookDetailContent?.endDate !== "current" && (
              <div className="flex gap-1 items-top">
                <p className="text-base">{bookDetailContent?.endDate}</p>
                {svgIcons.checkIcon}
              </div>
            )}
          </div>
          <div className="h-[100] pb-8"></div>
          {/* <p className="text-sm text-white opacity-80">
            {`Definition: ${getDef()}`}
            {"No definitions yet."}
          </p> */}
          {bookDeleteMode && (
            <div className="flex gap-4 mt-4">
              <button
                className="w-3/4 h-12 text-lg text-center rounded-full h-10 bg-[#ff000095] tracking-wide md:w-2/3"
                onClick={() => bookDeleteHandler()}
              >
                Confirm Delete Book
              </button>
              <button
                className="flex-1 w-1/4 h-12 text-lg bg-none rounded-full border-solid border-2 border-[#ffffff80]md:w-1/3"
                onClick={() => setBookDeleteMode(false)}
              >
                Cancel
              </button>
            </div>
          )}
          {!bookDeleteMode && (
            <div className="flex gap-3 mt-4 justify-between">
              <button
                className="flex-none w-1/4 h-12 text-lg rounded-full bg-[#ff000095] md:w-1/3"
                onClick={() => setBookDeleteMode(true)}
              >
                Delete
              </button>
              <button
                className="flex-1 w-3/4 h-12 text-lg bg-none rounded-full border-solid border-2 border-[#ffffff80]md:w-2/3"
                onClick={() => closeBookDetail()}
              >
                Close
              </button>
            </div>
          )}
        </div>}
      </div>
      <div className="mt-0">
        <div className="relative">
          <select
            className="block appearance-none w-full text-gray-700 py-3 pr-8 leading-tight bg-white focus:outline-none focus:bg-white focus:border-gray-500 border border-gray-400 border-t-0 border-l-0 border-r-0 text-lg"
            onChange={sortChangeHandler}
          >
            <option>Sort by: {sortType}</option>
            {sortList.map((s) => {
              if (s !== sortType) return <option key={s}>{s}</option>;
            })}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-black h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <ul className="mt-4 divide-y divide-dashed text-lg">
          {books.map((b: BookListData) => {
            return (
              <li
                className={
                  b.endDate === "current"
                    ? "py-1 hover:bg-white"
                    : "text-green-600 py-1 hover:bg-white"
                }
                key={b.book}
                onClick={() => clickBookHandler(b)}
              >
                <div className="flex justify-between w-full">
                  <div className="truncate flex gap-0 align-middle w-2/3">
                    {/* {b.endDate !== "current" && svgIcons.checkIcon} */}
                    <p className="truncate pr-4">{b.book}</p>
                  </div>
                  <p className="text-nowrap w-1/3 text-right">{`${b.startDate} - ${b.endDate}`}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default BookList;
