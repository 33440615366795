import { type User, type Word } from "./types";
import { tempServerWords } from "../components/tempData";
import { retrieveToken, saveToken, updateLocalPrefs } from "./localStorage";

// const baseURL = "http://localhost:3001";
const baseURL = "https://www.motscouture.com/api";

export const fetchWords = () => {
  console.log("fetching shared words...");
  const newWords = getServerWords();
  console.log(newWords);
  return [];
};

export async function getServerWords() {
  try {
    const response = await fetch(`${URL} + "/words`);
    const words = await response.json();
    console.log("response was", words);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error:", error);
    return tempServerWords;
  }
}

// export const postWord = (word: WordData) => {
//   console.log("data3", word);
//   const newWord = {
//     sharedid: word.sharedID,
//     localid: word.localID,
//     mot: word.word,
//     livre: word.book,
//     page: word.page,
//     date: word.date,
//     user: word.user,
//   };
//   console.log(`posting ${newWord}`);
//   sendServerWord(word);

//   let error = false;
//   if (error === false) {
//     return false;
//   }
//   return true;
// };

// async function sendServerWord(word: WordData) {
//   try {
//     const response = await fetch(URL + "/word", {
//       method: "POST", // or 'PUT'
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(word),
//     });

//     const result = await response.json();
//     return result;
//   } catch (error) {
//     console.error("Error:", error);
//     return error;
//   }
// }

export async function postUserData(url: string, method: string, data = {}) {
  try {
    const response = await fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    console.log("this errored at post");
  }
}

export async function postWordData(url: string, method: string, data: Word) {
  const token = retrieveToken();
  console.log("token (rT) is...", token);
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const postWord = (word: Word) => {
  const token: string = retrieveToken();
  console.log("token parameter is...", token);
  console.log("posting word:", word);
  postWordData(`${baseURL}/words`, "POST", {
    sharedid: word.sharedid,
    localid: word.localid,
    word: word.word,
    book: word.book,
    author: word.author,
    page: word.page,
    date: word.date,
    user: word.user,
    userid: word.userid,
  })
    .then((data) => {
      console.log("postWordResponse", data);
      return "success";
    })
    .catch((err) => {
      console.log(err);
      return "fail";
    });
};

export const postUser = (user: User, signupFailureHandler: any) => {
  postUserData(`${baseURL}/users`, "POST", {
    username: user.username,
    email: user.email,
    password: user.password,
  })
    .then((data) => {
      console.log(data.message);
      if (data.message === "Apologies, that username is unavailable.") {
        console.log('yes fail');
        signupFailureHandler(false);
        return "fail1";
      } else {
        signupFailureHandler(true);
        updateLocalPrefs("isUser", true);
        updateLocalPrefs("user", data.user);
        console.log("second success");
        return "success";
      }
    })
    .catch((err) => {
      console.log(err);
      console.log("second fail");
      return "fail2";
    });
  // updateLocalPrefs('isUser', true);
  // updateLocalPrefs('user', user.username);
  // console.log('meaningless2 - just means done with function');
};

export const signinUser = async (login: any, signedInState: any) => {
  postUserData(`${baseURL}/login`, "POST", {
    username: login.username,
    password: login.password,
  })
    .then((data) => {
      console.log(data);
      console.log("first success");
      console.log();
      signedInState(data.username);
      saveToken(data.token);
      updateLocalPrefs("user", data.username);
      updateLocalPrefs("userid", data.userid);
      return "success";
    })
    .catch((err) => {
      console.log(err);
      console.log("first fail");
      return "fail";
      // signedInState(false);
    });
  // console.log('meaningless1 - just means done with function');
};
