
const Heading = (props: any) => {
    return (
        <div className="m-auto pt-2  border-black/20 pb-0">
        <h1
          className="text-2xl text-bold leading-tight"
          onClick={() => props.setShowAbout(true)}
        >
          Mots Couture
        </h1>
        <div className="flex flex-row items-baseline justify-center">
          <p className="text-[16px] opacity-65">
            Interesting words are always in fashion.
          </p>
          {/* {!currentBook ? (
            <p className="text-[16px] opacity-65">
              Interesting words are always in fashion!
            </p>
          ) : (
            <p className="text-[16px] opacity-65">{`Currently reading ${currentBook}`}</p>
          )} */}
        </div>
        <p className="hidden ml-1 text-xs underline hover:no-underline">
          Learn more about this site
        </p>
      </div>
    );
};

export default Heading;