// import { svgIcons } from "./svgIcons";

type NewSigninAccountProps = {
  handleUsernameText: any;
  usernameText: any;
  handlePasswordText: any;
  passwordText: any;
  setSigninMode: any;
  signinHandler: any;
  signinModeHandler: any;
  signupModeHandler: any;
  signoutModeHandler: any;
  cancelSignin: any;
  deluxeSignin: boolean;
  isValid: any;
  isLoading: any;
  signinFailure: any;
  signinMode: boolean;
  signupMode: boolean;
  signedIn: boolean;
  user: string;
  cancelSignHandler: any;
};

const NewSigninAccount = (props: NewSigninAccountProps) => {
  return (
    <>
      <div className="text-black">
        {props.signinMode && props.user !== "" && (
          <div>
            {/* <h2 className="bg-[#2a4978] px-4 py-2 w-full rounded-t-xl text-white">{`Hi, ${props.user}!`}</h2> */}
            <h2 className="bg-[#2a4978] px-4 py-2 w-full rounded-t-xl text-white md:rounded-t-md">
              Account Sign In
            </h2>
            <p className="text-base text-black/70 px-4 py-3 pb-0">
              Enter a username & password
            </p>
          </div>
        )}
        {props.user === "" && (
          <div>
            {props.signinMode && props.user === "" && (
              <h2 className="bg-[#2a4978] px-4 py-2 w-full rounded-t-xl md:rounded-t-md text-white text-xl">
                {"Account Sign In"}
              </h2>
            )}
            {!props.signinFailure && (
              <p className="text-sm text-black/70 px-4 py-3 pb-0">
                Enter a username & password
              </p>
            )}
            {props.signinFailure && (
              <p className="text-base text-red-500 px-4 py-3 pb-0">
                Username and/or password is incorrect.
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2 p-4">
        <div className="flex flex-col -mx-3 mb-0 gap-4 md:flex-row md:items-baseline md:gap-6">
          <div className="w-full pr-3 pl-3 md:basis-1/2 md:w-1/3 md:pl-3 md:pr-0 md:mb-2">
            <div>
            <div className="flex justify-between items-baseline">
              <label
                className="md:block uppercase tracking-wide text-black/80 text-xs mb-2"
                htmlFor="grid-city"
              >
                username
              </label>
              <p className="text-sm md:text-xs text-black/60">One word, no spaces</p>
            </div>
            <input
              className={
                props.isValid.username
                  ? `appearance-none block w-full bg-white text-black/90 border border-gray-300 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800`
                  : `appearance-none block w-full bg-white text-black/90 border border-gray-300 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800 border-red-500`
              }
              id="caUsername"
              type="text"
              placeholder=""
              onChange={props.handleUsernameText}
              value={props.usernameText}
            />
                        {!props.isValid.username && (
              <p className="text-left text-sm text-red-500 mt-2">
                Please enter a valid username (one word, no spaces).
              </p>
            )}
            </div>
          </div>
          <div className="w-full pr-3 pl-3 md:basis-1/2 md:w-1/3 md:pl-0 mb-0 md:mb-0 mt-1">
            <div>
            <div className="flex justify-between items-baseline">
              <label
                className="md:block uppercase tracking-wide text-black/80 text-xs mb-2"
                htmlFor="caPassword"
              >
                Password
              </label>
              <p className="text-sm md:text-xs text-black/60">Min. 8 characters</p>
            </div>
            <input
              className={
                props.isValid.password
                  ? `appearance-none block w-full bg-white text-black/90 border border-gray-300 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800`
                  : `appearance-none block w-full bg-white text-black/90 border border-gray-300 rounded p-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800 border-red-500`
              }
              id="caPassword"
              type="password"
              placeholder=""
              // placeholder='not "DarcyHeartsLizzie"'
              onChange={props.handlePasswordText}
              value={props.passwordText}
            />
          </div>
          {!props.isValid.password && (
          <p className="text-left text-sm text-red-500 mt-2">
            Please enter a valid password (min. 8 characters).
          </p>
        )}
          </div>
        </div>
        <div className="flex gap-4 pb-2 mt-2">
          {props.isLoading && (
            <button
              disabled
              className="w-2/3 h-12 md:h-11 text-lg rounded-full bg-[#2a4978] text-white"
            >
              <svg
                className="animate-spin mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffffff"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z" />
              </svg>
            </button>
          )}
          {!props.isLoading && (
            <button
              className="w-2/3 h-12 md:h-11 text-lg rounded-full bg-[#2a4978] text-white"
              onClick={() => props.signinHandler()}
              //   disabled={props.isValid.username && props.isValid.password}
            >
              Sign In
            </button>
          )}
          <button
            className="w-1/3 h-12 md:h-11 text-lg bg-gray-500/10 rounded-full border-black/60 text-black/70"
            onClick={() => props.cancelSignHandler()}
          >
            Cancel
          </button>
        </div>
        <div className="w-full text-center justify-center flex gap-2 opacity-80 mt-0 pb-6 md:pb-1">
          <p className="text-base text-black">Don't have an account?</p>
          <button
            className="text-base text-[#2a4978] underline hover:no-underline hover:opacity-80"
            onClick={() => props.signinModeHandler("switch")}
          >
            Create one now
          </button>
        </div>
      </div>
    </>
  );
};

export default NewSigninAccount;
