const About = (props:any ) => {
  return (
    <>
      <div className="bg-white drop-shadow:lg flex flex-col absolute p-4 w-4/5 md:w-1/4 m-0 top-32 left-1/2 -translate-x-1/2 rounded-md z-50 md:max-w-xl md:m-auto md:mt-4 lg:mt-6 md:m-0 md:left-1/2 md:-translate-x-1/2 md:rounded-md">
        <h1 className="text-xl text-bold mb-2 text-center">Welcome to Mots Couture</h1>
        <p className="text-base leading-tight text-gray-800 pb-1 text-sm text-center">
          A fun utility for readers who love words.
        </p>
        <p className="text-base leading-tight text-gray-800 pb-3 border-b border-solid border-[#cccccc] text-sm">
          
        </p>
        <p className="text-base leading-tight text-gray-800 py-3 text-sm">
          This will be fun & informative onboarding about history, how to use, tips & tricks, etc... lorem ipsum...
        </p>
        <p className="text-base leading-tight text-gray-800 pb-3  border-b border-solid border-[#cccccc] text-sm">
          Build 2.50
        </p>
        <button
          className="bg-none border border-black/80 hover:bg-[#cccccc]/80 text-black/90 mt-4 py-2 px-4 rounded-full w-full tracking-wide"
          onClick={() => props.setShowAbout(false)}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default About;
