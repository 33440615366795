const CurrentBook = (props: any) => {
  return (<>
      {/* {props.currentBook.title !== "" &&
        props.currentBook.title !== "No Book, Just a Word" &&
        props.currentBook.title !== "+ Add Book" &&
        props.currentBook.title !== "Choose a Book" && <div className="bg-gray-500/10 rounded-lg w-full border-black/30">
      <div className="pr-0 flex flex-col items-left justify-center">
        <div className="bg-black/10 w-full rounded-t-base flex justify-between gap-1 px-2 items-center">
          <svg
            className="fill-black w-[16px] h-[16px] opacity-60"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M240-347q14-7 29-10t31-3h20v-440h-20q-25 0-42.5 17.5T240-740v393Zm160-13h320v-440H400v440Zm-160 13v-453 453Zm60 267q-58 0-99-41t-41-99v-520q0-58 41-99t99-41h420q33 0 56.5 23.5T800-800v501q0 8-6.5 14.5T770-270q-14 7-22 20t-8 30q0 17 8 30.5t22 19.5q14 6 22 16.5t8 22.5v10q0 17-11.5 29T760-80H300Zm0-80h373q-6-14-9.5-28.5T660-220q0-16 3-31t10-29H300q-26 0-43 17.5T240-220q0 26 17 43t43 17Z" />
          </svg>
          <h2 className="md:block text-black text-[13px] py-1 pt-[6px] text-bold opacity-90 text-center">
            Currently Reading
          </h2>
          <svg
            className="fill-none w-[16px] h-[16px] opacity-80"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M240-347q14-7 29-10t31-3h20v-440h-20q-25 0-42.5 17.5T240-740v393Zm160-13h320v-440H400v440Zm-160 13v-453 453Zm60 267q-58 0-99-41t-41-99v-520q0-58 41-99t99-41h420q33 0 56.5 23.5T800-800v501q0 8-6.5 14.5T770-270q-14 7-22 20t-8 30q0 17 8 30.5t22 19.5q14 6 22 16.5t8 22.5v10q0 17-11.5 29T760-80H300Zm0-80h373q-6-14-9.5-28.5T660-220q0-16 3-31t10-29H300q-26 0-43 17.5T240-220q0 26 17 43t43 17Z" />
          </svg>
        </div>
        <div className="flex flex-col pt-1 pb-2">
          <div className="flex justify-start items-baseline px-2">
            <p className="flex w-full gap-0 items-baseline justify-center">
              <span className="text-[13px] pt-[3px] text-nowrap truncate text-left">{props.currentBook.title}</span><span className="text-[11px] opacity-80 text-left pt-[1px] text-nowrap">{`, p. ${props.getLastEntryFromBook(props.currentBook.title).page}`}</span>
            </p>
          </div>
          <p className="text-[11px] opacity-80 mt-[1px]">
            {props.currentBook.author}
          </p>
        </div>
      </div>
    </div>} */}
    <div className="bg-gray-500/15 md:bg-gray-500/15 rounded-lg w-full border-black/30 h-30">
    <div className="pr-0 flex flex-col items-left justify-center">
        <div className="bg-black/10 w-full rounded-t-base flex justify-center gap-1 px-2 items-center">
          <svg
            className="fill-black w-[16px] h-[16px] opacity-60"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M240-347q14-7 29-10t31-3h20v-440h-20q-25 0-42.5 17.5T240-740v393Zm160-13h320v-440H400v440Zm-160 13v-453 453Zm60 267q-58 0-99-41t-41-99v-520q0-58 41-99t99-41h420q33 0 56.5 23.5T800-800v501q0 8-6.5 14.5T770-270q-14 7-22 20t-8 30q0 17 8 30.5t22 19.5q14 6 22 16.5t8 22.5v10q0 17-11.5 29T760-80H300Zm0-80h373q-6-14-9.5-28.5T660-220q0-16 3-31t10-29H300q-26 0-43 17.5T240-220q0 26 17 43t43 17Z" />
          </svg>
          <h2 className="md:block text-black text-[13px] py-1 pt-[6px] text-bold opacity-90 text-center">
            Currently Reading
          </h2>
          <svg
            className="fill-none w-[16px] h-[16px] opacity-80"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M240-347q14-7 29-10t31-3h20v-440h-20q-25 0-42.5 17.5T240-740v393Zm160-13h320v-440H400v440Zm-160 13v-453 453Zm60 267q-58 0-99-41t-41-99v-520q0-58 41-99t99-41h420q33 0 56.5 23.5T800-800v501q0 8-6.5 14.5T770-270q-14 7-22 20t-8 30q0 17 8 30.5t22 19.5q14 6 22 16.5t8 22.5v10q0 17-11.5 29T760-80H300Zm0-80h373q-6-14-9.5-28.5T660-220q0-16 3-31t10-29H300q-26 0-43 17.5T240-220q0 26 17 43t43 17Z" />
          </svg>
        </div>
        <div className="flex flex-col pt-2 pb-2">
          <div className="flex justify-start items-baseline px-2">
            <p className="flex w-full gap-0 items-baseline justify-center">
              <span className="text-base md:text-xl pt-[3px] text-nowrap truncate text-left">{props.currentBook.title}</span><span className="text-sm opacity-80 text-left pt-[1px] text-nowrap">{`, p. ${props.getLastEntryFromBook(props.currentBook.title).page}`}</span>
            </p>
            {/* <p className="text-[11px] opacity-80 w-1/4">{`, p. ${
              props.getLastEntryFromBook(props.currentBook.title).page
            }`}</p> */}
          </div>
          <p className="text-sm md:text-base opacity-80 mt-[1px]">
            {props.currentBook.author}
          </p>
        </div>
      </div>
    </div>
    </>);
};

export default CurrentBook;
