import { svgIcons } from "./svgIcons";

type SigninAccountProps = {
  handleUsernameText: any;
  usernameText: any;
  handlePasswordText: any;
  passwordText: any;
  setSigninMode: any;
  signinHandler: any;
  signinModeHandler: any;
  signupModeHandler: any;
  signoutModeHandler: any;
  cancelSignin: any;
  deluxeSignin: boolean;
  isValid: any;
  isLoading: any;
  signinFailure: any;
};

const SigninAccount = (props: SigninAccountProps) => {
  return (
    <div className="mt-2">
      {props.deluxeSignin && <div className="flex flex-col md:flex-row -mx-3 mb-4 gap-4">
      {props.signinFailure && <p className="text-left px-3 text-[14px] text-red-500">Sign in failed. Incorrect username and/or password.</p>}
        <div className="w-full pr-3 pl-3 md:basis-1/2 md:w-1/3 md:pl-3 md:pr-0 md:mb-0">
          <label
            className="md:block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-city"
          >
            username
          </label>
          <input
            className={
              props.isValid.username
                ? `appearance-none block w-full bg-white text-gray-800 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`
                : `appearance-none block w-full bg-white text-gray-800 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-red-500`
            }
            id="grid-city"
            type="text"
            placeholder=""
            onChange={props.handleUsernameText}
            value={props.usernameText}
          />
        </div>
        <div className="w-full pr-3 pl-3 md:basis-1/2 md:w-1/3 md:pl-0 mb-0 md:mb-0">
          <label
            className="md:block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-zip"
          >
            Password
          </label>
          <input
            className={
              props.isValid.password
                ? `appearance-none block w-full bg-white text-gray-800 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`
                : `appearance-none block w-full bg-white text-gray-800 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 border-red-500`
            }
            id="grid-zip"
            type="password"
            placeholder=""
            // placeholder='not "DarcyHeartsLizzie"'
            onChange={props.handlePasswordText}
            value={props.passwordText}
          />
        </div>
      </div>}
      {!props.deluxeSignin && <div className="flex flex-row -mx-3 mb-3">
        <div className="w-full md:w-full px-3 md:mb-0">
          <label
            className="hidden uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-zip"
          >
            Password
          </label>
          <input
            className="appearance-none block w-full bg-white text-gray-800 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-zip"
            type="password"
            placeholder="password"
            // placeholder='not "DarcyHeartsLizzie"'
            onChange={props.handlePasswordText}
            value={props.passwordText}
          />
        </div>
      </div>}
      <div className="flex w-full gap-4 pb-2">
      {props.isLoading && <button disabled
          className="w-2/3 w-14 rounded h-10 bg-[#2a4978] text-white text-center"
        >
          <svg className="animate-spin mx-auto" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"/></svg>
        </button>}
        {!props.isLoading && <button
          className="w-2/3 w-14 rounded h-10 bg-[#2a4978] text-white"
          onClick={() => props.signinHandler()}
        >
          Sign In
        </button>}
        <button
          className="w-1/3 bg-none rounded border-solid border-2 border-[#66666680]"
          onClick={() => props.cancelSignin()}
        >
          Cancel
        </button>
      </div>
      {!props.deluxeSignin && <div className="w-full text-center justify-center flex gap-1 opacity-80 mt-1">
        <p className="text-sm">Not you?</p>
        <button
          className="text-sm underline hover:no-underline hover:opacity-60"
          onClick={() => props.signoutModeHandler()}
        >
          Sign in with a different account
        </button>
      </div>}
    </div>
  );
};

export default SigninAccount;
