import { useState } from "react";

import { svgIcons } from "./components/svgIcons";
import { type Word } from "./utils/types";

type UserProps = {
  user: string;
  data: Word[];
  readingReminderHandler: any;
  newWordAlertHandler: any;
  allowNotifHandler: any;
  allowNotifPref: boolean;
  readingReminderPref: boolean;
  newWordAlertPref: boolean;
  deletePrefsHandler: any;
  deleteWordsHandler: any;
  deleteAllHandler: any;
  retrieveAllYourSharedWordsHandler: any;
  addBtnLeft: boolean;
  setAddBtnLeft: any;
};

const notificationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#454545"
    height="20"
    viewBox="0 -960 960 960"
    width="20"
  >
    <path d="M200-200q-17 0-28.5-11.5T160-240q0-17 11.5-28.5T200-280h40v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h40q17 0 28.5 11.5T800-240q0 17-11.5 28.5T760-200H200Zm280-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" />
  </svg>
);

const notificationOffIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#999999"
    height="20"
    viewBox="0 -960 960 960"
    width="20"
  >
    <path d="M646-200H200q-17 0-28.5-11.5T160-240q0-17 11.5-28.5T200-280h40v-280q0-33 8.5-65t25.5-61l60 60q-7 16-10.5 32.5T320-560v280h248L84-764q-11-11-11-28t11-28q11-11 28-11t28 11l680 680q11 11 11.5 27.5T820-84q-11 11-28 11t-28-11L646-200Zm74-154-80-80v-126q0-66-47-113t-113-47q-26 0-50 8t-44 24l-58-58q20-16 43-28t49-18v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v206Zm-276-50Zm36 324q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80Zm33-481Z" />
  </svg>
);

const UserPage = (props: UserProps) => {
  const [allowNotifications, setAllowNotifications] = useState(false);

  return (
    <div className="flex flex-col gap-4 mt-0">
      <div className="flex justify-between">
        {/* <h2 className="text-xl text-bold">{`Hi, ${props.user}!`}</h2> */}
      </div>
      {/* <p className="opacity-80 mt-2">{`This is a placeholder for ${user}'s account features.`}</p> */}
      <div className="bg-none border border-black/20 rounded-xl">
        <p className="text-black text-lg text-bold mb-1 bg-black/10 rounded-t-xl px-3 py-2">
          Preferences
        </p>
        <div className="flex flex justify-between items-start gap-1 p-3 pt-1">
          <div className="flex flex-col">
            <div className="flex gap-2 align-baseline w-3/4">
              <p className="text-bold text-base">Add Button:</p>
              {props.addBtnLeft && <div className="text-[#C84B31]">Right</div>}
              {!props.addBtnLeft && <div className="text-[#C84B31]">Left</div>}
            </div>
            <div className="flex gap-3 justify-between">
              <p className="text-sm leading-tight opacity-80 pt-1">
                Choose the left or right side at bottom.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-end w-1/4 mb-6 mt-2 pr-3 w-1/4">
            <label
              htmlFor="addSideToggle"
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  id="addSideToggle"
                  type="checkbox"
                  className="sr-only"
                  onChange={() => props.setAddBtnLeft(!props.addBtnLeft)}
                  checked={props.addBtnLeft}
                />
                <div className="w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></div>
                <div className="dot3 absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="bg-none border border-black/20 rounded-xl">
        <div className="flex justify-between bg-black/10 rounded-t-xl mb-1 px-3 py-2">
          <p className="text-lg text-bold">Notifications</p>
          <div className="flex items-center justify-end w-1/4 pr-3 pt-1">
            <label
              htmlFor="allowNotifPrefToggle"
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  id="allowNotifPrefToggle"
                  type="checkbox"
                  className="sr-only"
                  onChange={() => props.allowNotifHandler()}
                  checked={props.allowNotifPref}
                />
                <div className="w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></div>
                <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
              </div>
            </label>
          </div>
        </div>
        {!props.allowNotifPref && (
          <div className="flex flex-col gap-1 p-3 pt-1">
            <div className="flex gap-3 justify-between">
              <p className="text-sm leading-tight opacity-80 w-full pt-1">
                Allow word-related notifications.
              </p>
            </div>
          </div>
        )}
        {props.allowNotifPref && <div className="flex flex-col gap-1 p-3 pt-1">
          <div className="flex justify-between align-center">
            <div className="flex gap-3">
              <p className="text-bold">Reading Reminders</p>
              {props.readingReminderPref && (
                <div className="pt-[2px]">{notificationIcon}</div>
              )}
              {!props.readingReminderPref && (
                <div className="pt-[2px]">{notificationOffIcon}</div>
              )}
            </div>
            <div className="flex items-center justify-end w-1/4 pr-3 pt-1">
              <label
                htmlFor="readingReminderPrefToggle"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    id="readingReminderPrefToggle"
                    type="checkbox"
                    className="sr-only"
                    onChange={() => props.readingReminderHandler()}
                    checked={props.readingReminderPref}
                  />
                  <div className="w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></div>
                  <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                </div>
              </label>
            </div>
          </div>
          <div className="flex gap-3 justify-between">
            <p className="text-sm leading-tight opacity-80 w-full pt-1">
              Receive a reminder encouraging you to pick up that book you
              haven't read in a while.
            </p>
          </div>
        </div>}
        {props.allowNotifPref && <div className="flex flex-col gap-1 py-2 pb-4 mx-3 border-t-2 border-[#cccccc]">
          <div className="flex justify-between align-center">
            <div className="flex gap-3">
              <p className="text-bold">New Word Alert</p>
              {props.newWordAlertPref && (
                <div className="pt-[2px]">{notificationIcon}</div>
              )}
              {!props.newWordAlertPref && (
                <div className="pt-[2px]">{notificationOffIcon}</div>
              )}
            </div>
            <div className="flex items-center justify-end w-1/4 pr-3 pt-1">
              <label
                htmlFor="newWordPrefToggle"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    id="newWordPrefToggle"
                    type="checkbox"
                    className="sr-only"
                    onChange={() => props.newWordAlertHandler()}
                    checked={props.newWordAlertPref}
                  />
                  <div className="w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></div>
                  <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                </div>
              </label>
            </div>
          </div>
          <div className="flex gap-3 justify-between">
            <p className="text-sm leading-tight opacity-80 w-full pt-1">
              Get notified when a new word is added to the shared list.
            </p>
          </div>
        </div>}
      </div>
      <div className="bg-none border border-black/20 rounded-xl">
        <p className="text-lg text-bold mb-1 bg-black/10 rounded-t-xl px-3 py-2">
          Manage Data
        </p>
        <div className="flex flex-col gap-3 p-3">
          <div className="pb-0 flex gap-3">
            <button
              className="w-full px-2 md:w-full rounded-full h-10 bg-[#2a497820] border-[#2a4978] text-black/80 text-baseline tracking-wide"
              onClick={() => props.deletePrefsHandler()}
            >
              Delete Preferences
            </button>
            <button
              className="w-full px-2 md:w-full rounded-full h-10 bg-[#C84B3120] border-[#C84B31] text-black/80 text-baseline tracking-wide"
              onClick={() => props.deleteWordsHandler()}
            >
              Delete Words
            </button>
          </div>
          <button
            className="w-full px-4 md:w-full rounded-full h-10 bg-gray-500/20 border-[#333333] text-black/80 text-baseline tracking-wide"
            onClick={() => props.deleteAllHandler()}
          >
            Delete All Local Data
          </button>
          <div className="border-t-2 border-[#cccccc]">
            <button
              className="mt-3 w-full px-4 md:w-full rounded-full h-12 bg-[#2D4263] border border-[#009688] text-white text-baseline tracking-wide"
              onClick={() => props.retrieveAllYourSharedWordsHandler()}
            >
              Retrieve Your Shared Words
            </button>
            {/* #009688 */}
            {/* #2a4978 */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
