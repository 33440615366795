const NewAccount = (props: any) => {
  return (
    <>
      {(props.signinMode || props.signupMode) && (
          <div style={{backgroundImage: `url('https://www.dalesmith.com/play/img/dots4.png')`}}
            className="absolute top-0 left-0 h-screen w-full bg-black/40"
            onClick={() => props.cancelSignHandler()}
          ></div>
        )}
      <div
          className={`bg-white absolute z-1000 pb-0 translate-y-0 left-0 w-full bottom-0 text-white rounded-t-xl opacity-100 md:rounded md:max-w-2xl md:w-[600px] md:rounded-md md:m-auto md:mt-4 lg:mt-5 md:mb-52 md:left-1/2 md:-translate-x-1/2 transition-all duration-300 ${
            props.showLogin
              ? "translate-y-0 opacity-100 md:translate-y-0 md:opacity-100 transition-all duration-300"
              : "translate-y-full opacity-0 md:translate-y-0 md:opacity-0 transition-opacity duration-300"
          }`}
        >
          {props.children}
        </div>
    </>
  );
};

export default NewAccount;
