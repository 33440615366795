import { SetStateAction, useEffect } from "react";

import { useState } from "react";

import { Book } from "./utils/types";

type ModalPropTypes = {
  signedIn: boolean;
  books: Book[];
  selectBook: any;
  setAddBookMode: any;
  setCurrentBook: any;
  toggleSetting: boolean;
  addBookHandler: any;
  addWordHandler: any;
  addBookMode: any;
  currentBook: Book;
  shareToggle: boolean;
  shareToggleHandler: any;
  defToggle: boolean;
  defToggleHandler: any;
  closeModal: any;
};

interface WordData {
  book: string;
  author: string;
  word: string;
  page: number;
  prevState: null;
}

interface BookData {
  book: string;
  author: string;
  prevState: null;
}

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="36"
    viewBox="0 -960 960 960"
    width="36"
  >
    <path d="M480-438 270-228q-9 9-21 9t-21-9q-9-9-9-21t9-21l210-210-210-210q-9-9-9-21t9-21q9-9 21-9t21 9l210 210 210-210q9-9 21-9t21 9q9 9 9 21t-9 21L522-480l210 210q9 9 9 21t-9 21q-9 9-21 9t-21-9L480-438Z" />
  </svg>
);

const Modal = (props: ModalPropTypes) => {
  const [newBookInput, setNewBookInput] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [justWord, setJustWord] = useState(false);
  const [wordData, setWordData] = useState<WordData | null>(null);
  const [bookData, setBookData] = useState<BookData | null>(null);
  const [whichBook, setWhichBook] = useState({ title: "", author: "" });
  const [bookText, setBookText] = useState("");
  const [authorText, setAuthorText] = useState("");
  const [wordText, setWordText] = useState("");
  const [pageText, setPageText] = useState("");
  const [shareToggle, setShareToggle] = useState(props.toggleSetting);

  // useEffect(() => {
  //   if (props.books.length === 0 && props.currentBook.title === "+ Add Book") {
  //     setNewBookInput(true);
  //   } else if (props.books.length > 0 && props.currentBook.title === "+ Add Book") {
  //     props.setCurrentBook({ title: "No Book, Just a Word", author: "" })
  //     setNewBookInput(false);
  //   }
  // }, [props.books]);

  useEffect(() => {
    if (props.currentBook.title === "" || props.currentBook.title === "+ Add Book") {
      props.setCurrentBook({ title: "No Book, Just a Word", author: "" })
      console.log('switching...');
      setDisabled(true);
    }
  }, []);

  const bookChangeHandler = (e: { target: { value: any } }) => {
    const selection = e.target.value;
    console.log("selection", selection);
    if (selection === "Choose a Book") {
      setDisabled(() => true);
    } else if (selection === "No Book, Just a Word") {
      setJustWord(() => true);
      setNewBookInput(() => false);
      props.setCurrentBook({ title: selection, author: "" });
    } else if (selection === "+ Add Book") {
      setJustWord(() => false);
      setNewBookInput(() => true);
      props.setCurrentBook({ title: selection, author: "" });
    } else {
      const currentBook = props.books.filter((book) => {
        return book.title === selection;
      });
      console.log("currentBook", currentBook[0]);
      props.setCurrentBook(currentBook[0]);
      console.log(`Switching book to: ${currentBook[0].title}`);
      setJustWord(false);
      setNewBookInput(() => false);
    }
  };

  const handleBookText = (e: { target: { value: SetStateAction<string> } }) => {
    setBookText(e.target.value);
  };

  const handleAuthorText = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setAuthorText(e.target.value);
  };

  const handleWordText = (e: { target: { value: SetStateAction<string> } }) => {
    setWordText(e.target.value);
  };

  const handlePageText = (e: { target: { value: SetStateAction<string> } }) => {
    setPageText(e.target.value);
  };

  const addNewBookHandler = () => {
    if (bookText.trim() === "" || authorText.trim() === "") {
      return;
    } else {
      props.addBookHandler({ title: bookText, author: authorText });
      setNewBookInput((prevValue) => false);
      props.addWordHandler(
        { title: bookText, author: authorText },
        "start",
        "0"
      );
    }
  };

  const addNewWordHandler = () => {
    if (props.currentBook.title === "No Book, Just a Word") {
      console.log("hitting this...");
      if (wordText.trim() === "") {
        return;
      } else {
        props.addWordHandler(props.currentBook, wordText, pageText);
      }
    } else {
      console.log("hitting that...");
      console.log(props.currentBook.title)
      if (wordText.trim() === "" || pageText.trim() === "") {
        return;
      } else {
        props.addWordHandler(props.currentBook, wordText, pageText);
      }
    }
  };

  useEffect(() => {
    console.log("this is the modal loading...");
    props.shareToggle === true
      ? console.log("Word will be shared.")
      : console.log("Word will not be shared.");
  }, []);

  return (
    <>
      <div className="slideIn bg-white drop-shadow:lg flex flex-col absolute p-4 w-full rounded-b-md top-0 z-50 md:max-w-xl md:m-auto md:mt-4 lg:mt-6 md:m-0 md:left-1/2 md:-translate-x-1/2 md:rounded-md">
        <div className="fadeIn">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-2xl text-bold">Add</p>
            </div>
            <div className="-mr-1" onClick={props.closeModal}>
              {closeIcon}
            </div>
          </div>
          <div className="pr-14 py-2">
            <hr />
          </div>
          <div>
            <form>
              <div className="w-full mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
                  htmlFor="grid-state"
                >
                  Select Book
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    onChange={bookChangeHandler}
                    value={props.currentBook.title}
                  >
                    {/* <option>Choose a Book</option> */}
                    <option>+ Add Book</option>
                    <option>No Book, Just a Word</option>
                    {props.books.map((b: Book) => (
                      <option key={b.title}>{b.title}</option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              {newBookInput && (
                <div>
                  <div className="mb-4">
                    <label
                      className="hidden block text-gray-700 text-sm mb-2"
                      htmlFor="newBook"
                    >
                      Book Title
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="newBook"
                      type="text"
                      placeholder="Title"
                      onChange={handleBookText}
                      value={bookText}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="hidden block text-gray-700 text-sm mb-2"
                      htmlFor="newAuthor"
                    >
                      Author
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="newAuthor"
                      type="text"
                      placeholder="Author"
                      onChange={handleAuthorText}
                      value={authorText}
                    />
                  </div>
                </div>
              )}
              {!newBookInput && (
                <div className="flex flex-row -mx-3 mb-2">
                  <div className="w-full md:w-full px-3 mb-4 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
                      htmlFor="grid-city"
                    >
                      Word
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-city"
                      type="text"
                      placeholder="Word"
                      onChange={handleWordText}
                      value={wordText}
                    />
                  </div>
                  {props.currentBook.title !== "No Book, Just a Word" && (
                    <div className="basis-2/5 w-full md:w-1/3 px-3 mb-4 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
                        htmlFor="grid-zip"
                      >
                        Page
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-zip"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        placeholder="128"
                        onChange={handlePageText}
                        value={pageText}
                      />
                    </div>
                  )}
                </div>
              )}
            </form>
            {!newBookInput && (
              <>
                <div className="flex items-center justify-start w-full mb-6">
                  <label
                    htmlFor="shareToggle"
                    className="flex items-center cursor-pointer"
                  >
                    <div className="relative">
                      <input
                        disabled={props.signedIn === false}
                        id="shareToggle"
                        type="checkbox"
                        className="sr-only"
                        onChange={props.shareToggleHandler}
                        checked={!props.signedIn ? false : props.shareToggle}
                      />
                      <div className="w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                    <div className="ml-3 text-black/60 font-medium">
                      {!props.signedIn
                        ? `Sign in to share word with community list`
                        : `Share word with community list`}
                    </div>
                  </label>
                </div>
                <div className="flex items-center justify-start w-full mb-6 hidden">
                  <label
                    htmlFor="defToggle"
                    className="flex items-center cursor-pointer"
                  >
                    <div className="relative">
                      <input
                        id="defToggle"
                        type="checkbox"
                        className="sr-only"
                        onChange={props.defToggleHandler}
                        checked={props.defToggle}
                      />
                      <div className="w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></div>
                      <div className="dot2 absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                    </div>
                    <div className="ml-3 text-gray-600 font-medium">
                      Show definition after addding
                    </div>
                  </label>
                </div>
                <button
                  className="bg-[#C84B31] hover:bg-[#C84B31]/80 text-white text-lg h-12 md:h-11 py-2 px-4 rounded-full w-full tracking-wide"
                  onClick={() => addNewWordHandler()}
                >
                  Add Word
                </button>
              </>
            )}
            {newBookInput && (
              <button
                className="bg-[#C84B31] hover:bg-[#C84B31]/80 text-white text-lg h-12 md:h-11 py-2 px-4 rounded-full w-full tracking-wide"
                onClick={() => addNewBookHandler()}
              >
                Add Book
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <div
        className="absolute top-0 left-0 h-screen w-full bg-black/30 z-40"
        onClick={props.closeModal}
      ></div> */}
      <div style={{backgroundImage: `url('https://www.dalesmith.com/play/img/dots4.png')`}}
            className="absolute top-0 left-0 h-screen w-full bg-black/40 z-100"
            onClick={() => props.closeModal()}
          ></div>
    </>
  );
};

export default Modal;
