import { useState, useEffect } from "react";
import { svgIcons } from "./components/svgIcons";

type FullpageProps = {
  closeFullPage: any;
  extrasType: any;
  addBookHandler: any;
  addWordHandler: any;
  signoutHandler: any;
  children: any;
  user: string;
};

interface SingleWordData {
  book: string;
  word: string;
  page: number;
  prevState: null;
}

interface SingleBookData {
  book: string;
  prevState: null;
}

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="36"
    viewBox="0 -960 960 960"
    width="36"
  >
    <path d="M480-438 270-228q-9 9-21 9t-21-9q-9-9-9-21t9-21l210-210-210-210q-9-9-9-21t9-21q9-9 21-9t21 9l210 210 210-210q9-9 21-9t21 9q9 9 9 21t-9 21L522-480l210 210q9 9 9 21t-9 21q-9 9-21 9t-21-9L480-438Z" />
  </svg>
);

const FullPage = (props: FullpageProps) => {
  const [newBookInput, setNewBookInput] = useState(false);
  const [wordData, setWordData] = useState<SingleWordData | null>(null);
  const [bookData, setBookData] = useState<SingleBookData | null>(null);
  const [whichBook, setWhichBook] = useState("");
  const [bookText, setBookText] = useState("");
  const [wordText, setWordText] = useState("");
  const [pageText, setPageText] = useState("");
  const [showPage, setShowPage] = useState(false);
  const [showContent, setShowContent] = useState(false);

  
  useEffect(() => {
    setTimeout(() => {
        setShowPage(() => true);
      }, 200);
      setTimeout(() => {
        setShowContent(() => true);
      }, 300);
}, []);

  return (
    <>
      <div className={"bg-white drop-shadow:lg flex flex-col absolute w-full h-full md:h-3/4 rounded-none top-0 z-50 md:max-w-2xl md:rounded-md md:m-auto md:mt-4 lg:mt-5 md:m-0 md:left-1/2 md:-translate-x-1/2 pb-4"}>
        <div className={showPage ? "opacity-100 flex items-center justify-between bg-[#ECDBBA90] p-3 px-4  transition-all ease-out duration-300" : "opacity-0 flex items-center justify-between bg-[#ECDBBA90] p-3 px-4"}>
          <div className="flex gap-4 items-middle">
            <div className="flex items-baseline">
            {props.extrasType !== "account" && <p className="text-2xl text-bold capitalize">{props.extrasType}</p>}
            {props.extrasType === "account" && (
              <p className="text-2xl text-bold capitalize">{`${props.user}`}</p>)}
            </div>
            {/* {props.extrasType === "booklist" && (
              <button disabled
              className="rounded-full h-8 bg-none text-white text-sm opacity-80 hover:opacity-60"
              onClick={() => props.addWordHandler()}
            >
             {svgIcons.addIconSm}
            </button>
            )} */}
            {/* {props.extrasType === "wordlist" && (
              <button disabled
                className="rounded-full h-8 bg-none text-white text-sm opacity-80 hover:opacity-60"
                onClick={() => props.addWordHandler()}
              >
               {svgIcons.addIconSm}
              </button>
            )} */}
            {props.extrasType === "account" && (
            
              <button
                className="px-4 md:w-full rounded-full h-8 bg-[#2a4978] text-white text-base tracking-wide"
                onClick={() => props.signoutHandler()}
              >
                Sign Out
              </button>
              
            )}
          </div>
          <div className="-mr-1" onClick={props.closeFullPage}>
            {closeIcon}
          </div>
        </div>
        <div className={showContent ? "opacity-100 overflow-scroll px-4 transition-all ease-out duration-500" : "opacity-0 overflow-scroll px-4"}>{props.children}</div>
      </div>
      <div
        className="absolute top-0 left-0 w-full h-full bg-black/30 z-40"
        onClick={props.closeFullPage}
      ></div>
    </>
  );
};

export default FullPage;
