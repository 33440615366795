import { svgIcons } from "./components/svgIcons";

const WordDjour = (props: any) => {
  return (
    <div className="bg-gray-500/15 md:bg-gray-500/15 rounded-lg w-full border-black/30 h-30">
      {props.currentBook.title !== "" &&
        props.currentBook.title !== "No Book, Just a Word" &&
        props.currentBook.title !== "+ Add Book" &&
        props.currentBook.title !== "Choose a Book" && (
          <div
            className="pr-0 flex flex-col items-left justify-start"
            onClick={() => props.djourHandler()}
          >
            <div className="bg-black/10 w-full rounded-t-base flex justify-between gap-1 px-2">
              <svg
                className="fill-black opacity-60 pt-[1px] w-[30px] h-[30px]"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M400-360q-8 0-14-6t-6-14v-200q0-8 6-14t14-6h140q17 0 28.5 11.5T580-560v40q0 17-11.5 28.5T540-480q17 0 28.5 11.5T580-440v40q0 17-11.5 28.5T540-360H400Zm40-150h80v-30h-80v30Zm0 90h80v-30h-80v30Zm-290 60q-13 0-21.5-8.5T120-390v-170q0-17 11.5-28.5T160-600h120q17 0 28.5 11.5T320-560v170q0 13-8.5 21.5T290-360q-13 0-21.5-8.5T260-390v-30h-80v30q0 13-8.5 21.5T150-360Zm30-120h80v-60h-80v60Zm500 120q-17 0-28.5-11.5T640-400v-160q0-17 11.5-28.5T680-600h120q17 0 28.5 11.5T840-560v20q0 13-8.5 21.5T810-510q-13 0-21.5-8.5T780-540h-80v120h80q0-13 8.5-21.5T810-450q13 0 21.5 8.5T840-420v20q0 17-11.5 28.5T800-360H680Z" />
              </svg>
              <h2 className="md:block text-black text-[13px] py-1 pt-[6px] text-bold opacity-100 text-center">
                Word d'Jour
              </h2>
              <svg
                className="fill-none opacity-80"
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M400-360q-8 0-14-6t-6-14v-200q0-8 6-14t14-6h140q17 0 28.5 11.5T580-560v40q0 17-11.5 28.5T540-480q17 0 28.5 11.5T580-440v40q0 17-11.5 28.5T540-360H400Zm40-150h80v-30h-80v30Zm0 90h80v-30h-80v30Zm-290 60q-13 0-21.5-8.5T120-390v-170q0-17 11.5-28.5T160-600h120q17 0 28.5 11.5T320-560v170q0 13-8.5 21.5T290-360q-13 0-21.5-8.5T260-390v-30h-80v30q0 13-8.5 21.5T150-360Zm30-120h80v-60h-80v60Zm500 120q-17 0-28.5-11.5T640-400v-160q0-17 11.5-28.5T680-600h120q17 0 28.5 11.5T840-560v20q0 13-8.5 21.5T810-510q-13 0-21.5-8.5T780-540h-80v120h80q0-13 8.5-21.5T810-450q13 0 21.5 8.5T840-420v20q0 17-11.5 28.5T800-360H680Z" />
              </svg>
            </div>
            <div className="flex flex-col pt-1 pb-2">
              <p className="text-base md:text-xl truncate text-black">
                {props.wordDJour.word}
              </p>
              {props.wordDJour.book !== "" && (
                <p className="text-sm md:text-base opacity-80 truncate text-black">{`${props.wordDJour.book}, p. ${props.wordDJour.page}`}</p>
              )}
            </div>
          </div>
        )}
      {(props.currentBook.title === "" ||
        props.currentBook.title === "No Book, Just a Word" ||
        props.currentBook.title === "+ Add Book" ||
        props.currentBook.title === "Choose a Book") && (
        <div
          className="pr-0 flex flex-col items-left justify-start"
          onClick={() => props.djourHandler()}
        >
          <div className="bg-black/10 w-full rounded-t-lg flex justify-center gap-1 px-2">
            <svg
              className="fill-black/60 pb-[3px]"
              xmlns="http://www.w3.org/2000/svg"
              height="30"
              viewBox="0 -960 960 960"
              width="30"
            >
              <path d="M400-360q-8 0-14-6t-6-14v-200q0-8 6-14t14-6h140q17 0 28.5 11.5T580-560v40q0 17-11.5 28.5T540-480q17 0 28.5 11.5T580-440v40q0 17-11.5 28.5T540-360H400Zm40-150h80v-30h-80v30Zm0 90h80v-30h-80v30Zm-290 60q-13 0-21.5-8.5T120-390v-170q0-17 11.5-28.5T160-600h120q17 0 28.5 11.5T320-560v170q0 13-8.5 21.5T290-360q-13 0-21.5-8.5T260-390v-30h-80v30q0 13-8.5 21.5T150-360Zm30-120h80v-60h-80v60Zm500 120q-17 0-28.5-11.5T640-400v-160q0-17 11.5-28.5T680-600h120q17 0 28.5 11.5T840-560v20q0 13-8.5 21.5T810-510q-13 0-21.5-8.5T780-540h-80v120h80q0-13 8.5-21.5T810-450q13 0 21.5 8.5T840-420v20q0 17-11.5 28.5T800-360H680Z" />
            </svg>
            <h2 className="md:block text-black text-sm py-1 text-bold opacity-100 text-center">
              Word d'Jour
            </h2>
            <svg
              className="fill-none opacity-80"
              xmlns="http://www.w3.org/2000/svg"
              height="30"
              viewBox="0 -960 960 960"
              width="30"
            >
              <path d="M400-360q-8 0-14-6t-6-14v-200q0-8 6-14t14-6h140q17 0 28.5 11.5T580-560v40q0 17-11.5 28.5T540-480q17 0 28.5 11.5T580-440v40q0 17-11.5 28.5T540-360H400Zm40-150h80v-30h-80v30Zm0 90h80v-30h-80v30Zm-290 60q-13 0-21.5-8.5T120-390v-170q0-17 11.5-28.5T160-600h120q17 0 28.5 11.5T320-560v170q0 13-8.5 21.5T290-360q-13 0-21.5-8.5T260-390v-30h-80v30q0 13-8.5 21.5T150-360Zm30-120h80v-60h-80v60Zm500 120q-17 0-28.5-11.5T640-400v-160q0-17 11.5-28.5T680-600h120q17 0 28.5 11.5T840-560v20q0 13-8.5 21.5T810-510q-13 0-21.5-8.5T780-540h-80v120h80q0-13 8.5-21.5T810-450q13 0 21.5 8.5T840-420v20q0 17-11.5 28.5T800-360H680Z" />
            </svg>
          </div>
          <div className="flex flex-col pt-2 pb-1">
            <p className="text-xl pb-1">{props.wordDJour.word}</p>
            {props.wordDJour.book !== "" && (
              <p className="text-xs opacity-80 pb-1">{`${props.wordDJour.book}, p. ${props.wordDJour.page}`}</p>
            )}
            {props.wordDJour.book === "" && <p className="text-xl pb-4 ">{""}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default WordDjour;
