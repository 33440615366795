import { Word } from "./utils/types";

type WordQuizProps = {
    data: Word[],
    user: string,
    startGameHandler: any;
    gameMode: number;
}

const WordQuiz = ({ data, user, startGameHandler, gameMode }: WordQuizProps) => {
    return (
    <div className="mt-4 px-1">
        {gameMode === 0 && <div>
        <p className="text-lg ">Available Games:</p>
        <div
              className="shadow-sm bg-gray-500/10 border-4 border-black/20 flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full mt-4"
            >
              <div className="pr-2 w-3/4">
                <h2>Definitions</h2>
                <p className="text-base opacity-80 mt-1">
                  Test your word memory.
                </p>
              </div>
              <div className="w-1/4 text-center">
              <button className="rounded-full bg-black/50 px-4 py-2 text-white" onClick={() => startGameHandler(1)}>
                Start
              </button>
              </div>
            </div>
            <div
              className="shadow-sm bg-gray-500/10 border-4 border-black/20 flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full mt-4"
            >
              <div className="pr-2 w-3/4 text-sm">
                <h2>Spelling</h2>
                <p className="text-base opacity-80 mt-1">
                  Test your spelling
                </p>
              </div>
              <div className="w-1/4 text-center">
              <button className="rounded-full bg-black/50 px-4 py-2 text-white"
              onClick={() => startGameHandler(2)}>
                Start
              </button>
              </div>
            </div>
            </div>}
            {gameMode !== 0 && <div>
                {gameMode}
            </div>}
    </div>
    );  
};

export default WordQuiz;