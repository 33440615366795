import { updateLocalPrefs } from "../utils/localStorage";
import { getOnlyDate } from "../App";

import { type Word } from "../utils/types";

export const getWordDJour = (data: Word[]) => {
  const availWords = [...data];
  availWords.filter(w => w.word === "start" || w.word === "end");
    if (availWords.length === 0) {
        console.log('no words yet');
        return {
          localid: 0,
          sharedid: 1,
          user: "",
          userid: 0,
          book: "No Book",
          author: "No Author",
          word: "no word",
          page: "1",
          date: "No Date",
        };
    }
    // updateLocalPrefs('lastUse', getOnlyDate());
    let random = Math.floor(Math.random() * availWords.length);
    const randomWord = availWords[random];
    return randomWord;
  };