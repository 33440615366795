import WordDjour from "./wordDjour";
import CurrentBook from "./currentBook";
import { svgIcons } from "./components/svgIcons";

import { type Word } from "./utils/types";

type CarouselProps = {
  currentBook: any;
  localWords: Word[];
  djourHandler: any;
  wordDJour: any;
  onChangeHander: any;
  getLastEntryFromBook: any;
  isLoading: boolean;
};

const Carousel = (props: CarouselProps) => {
  return (
    <div className="flex gap-3 pt-3 h-30">
        {props.isLoading && <div className="rounded-lg bg-gray-500/15 md:bg-gray-500/10 w-full rounded-t-base flex justify-center gap-1 px-2 h-[94px] flex items-center"><div className="fill-black/80 animate-spin"><svg xmlns="http://www.w3.org/2000/svg" fill="#00000040" height="36" viewBox="0 -960 960 960" width="36"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"/></svg></div></div>}
      {!props.isLoading && <div
        className={
          props.currentBook.title === "" ||
          props.currentBook.title === "No Book, Just a Word" ||
          props.currentBook.title === "+ Add Book" ||
          props.currentBook.title === "Choose a Book"
            ? "w-full"
            : "hidden w-full"
        }
      >
        <WordDjour
          djourHandler={props.djourHandler}
          wordDJour={props.wordDJour}
          currentBook={props.currentBook}
        />
      </div>}
      {props.currentBook &&
        props.localWords.length > 0 &&
        props.currentBook.title !== "" &&
        props.currentBook.title !== "No Book, Just a Word" &&
        props.currentBook.title !== "+ Add Book" &&
        props.currentBook.title !== "Choose a Book" && (
          <div className="w-full">
            <CurrentBook
              currentBook={props.currentBook}
              onChangeHander={props.onChangeHander}
              getLastEntryFromBook={props.getLastEntryFromBook}
            />{" "}
          </div>
        )}
    </div>
  );
};

export default Carousel;
