import { type Word, type Preferences } from "./types";
import { tempData, tempWordDJour } from "../components/tempData";
import { getOnlyDate } from "../App";

export const getLocalWords = (): Word[] => {
  // const noData: WordData[] = [];
  const lw: Word[] = JSON.parse(localStorage.getItem("mcdata") || "[]");
  // console.log(lw[0]);
  // console.log(`lw is: ${lw}`);
  return lw;
};

export const getUser = () => {
  let rawData = localStorage.getItem("mcprefs") || null;
  if (rawData !== null) {
    return JSON.parse(rawData);
  } else {
    return;
  }
};

export const getLocalPrefs = (): Preferences => {
  let rawData = localStorage.getItem("mcprefs") || null;
  let parsedData: Preferences;
  if (rawData !== null) {
    parsedData = JSON.parse(rawData);
  } else {
    return setInitialPrefs();
  }

  // // console.log("data is", data);
  // if (parsedData === null) {
  //   // console.log("null");
  //   return setInitialPrefs();
  // }
  // console.log("not null");
  let prefs = { ...parsedData };
  // console.log("prefs is", prefs);
  return prefs;
};

export const saveLocalWords = (data: Word) => {
  const lw = getLocalWords();
  // console.log(`this is local (parsed): ${lw}`);
  lw.push(data);
  // console.log(`this is local with ${data} added.`, lw);
  localStorage.setItem("mcdata", JSON.stringify(lw));
};

export const updateLocalPrefs = (pref: string, val: any) => {
  // console.log(`wanting to update ${pref} as ${val}`);
  let oldPrefs = getLocalPrefs();
  // console.log("old Prefs", oldPrefs);
  let newPrefs = { ...oldPrefs };
  // console.log("new Prefs", newPrefs);
  switch (pref) {
    case "shareWord":
      newPrefs.shareWords = val;
      break;
    case "sharedWords":
      newPrefs.sharedWords = val;
      break;
    case "defPref":
      newPrefs.defPref = val;
      break;
    case "allowNotif":
      newPrefs.allowNotif = val;
      break;
    case "readingPref":
      newPrefs.readingPref = val;
      break;
    case "newwordPref":
      newPrefs.newwordPref = val;
      break;
    case "lineSize":
      newPrefs.lineSize = val;
      break;
    case "user":
      newPrefs.user = val;
      break;
    case "userid":
      newPrefs.userid = val;
      break;
    case "isuser":
      newPrefs.isUser = val;
      break;
    case "lastUse":
      newPrefs.lastUse = val;
      break;
      case "wordOfDay":
        newPrefs.wordOfDay = val;
        break;
    case "currentRead":
      newPrefs.currentRead = val;
      break;
    default:
    // console.log("nothing...");
  }
  // console.log("saving...", newPrefs);
  localStorage.setItem("mcprefs", JSON.stringify(newPrefs));
};

export const setInitialPrefs = () => {
  let initPrefs: Preferences = {
    shareWords: false,
    sharedWords: false,
    defPref: false,
    allowNotif: false,
    readingPref: false,
    newwordPref: false,
    lineSize: 2,
    user: "",
    userid: 0,
    isUser: false,
    currentRead: { title: "", author: "" },
    lastUse: getOnlyDate(),
    firstUse: true,
    wordOfDay: tempWordDJour,
  };
  localStorage.setItem("mcprefs", JSON.stringify(initPrefs));
  return initPrefs;
};

export const saveSampleLocalWords = () => {
  localStorage.setItem("mcdata", JSON.stringify(tempData));
};

export const clearLocalData = () => {
  localStorage.setItem("mcdata", JSON.stringify([]));
  localStorage.setItem("mcprefs", JSON.stringify({}));
};

export const deleteFromLocal = (word: string) => {
  console.log("Deleting from local...");
  // get from local
  console.log("Getting from local...");
  let lw = getLocalWords();
  console.log(lw);
  // find from local
  console.log("Finding in array...");
  lw = lw.filter((obj: { word: string }) => obj.word !== word);
  // const index = ls.indexOf(word);
  // if (index > -1) {
  //   // only splice array when item is found
  //   ls.splice(index, 1); // 2nd parameter means remove one item only
  console.log(lw);
  // remove from local
  // save new array to local
  localStorage.setItem("mcdata", JSON.stringify(lw));
  // update state and seed
};

export const saveToken = (t: string) => {
  localStorage.setItem("token", JSON.stringify(t));
};

export const retrieveToken = () => {
  let rawData = localStorage.getItem("token") || null;
  let token: any;
  if (rawData !== null) {
    token = JSON.parse(rawData);
  } else {
    if (token === null) {
      token = "";
    }
  }
  console.log("returning token: ");
  return token;
};
