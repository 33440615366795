export const createLocalID = (word: string): number => {
    let randomNumber = Math.abs(Math.floor(Math.random() * 10000));
    let randomNumberString = randomNumber.toString();
    let stringID = [];
    for (let i = 0; i < word.length / 2; i++) {
      stringID.push(word[i].charCodeAt(0) - 97);
    }
    let wordNumber = stringID.join("");
    let combinedString = wordNumber + randomNumberString;
    let combinedNumber = parseInt(combinedString);
    return combinedNumber;
  };

export const validateInput = (input: string, type: string) => {
    input = input.trim();
  
    if (input === "") {
      return false;
    }
  
    if (input.indexOf(" ") >= 0) {
      return false;
    }
  
    if (input.length < 8 && type === "password") {
      return false;
    }
  
    return true;
  };