import { svgIcons } from "./svgIcons";


type AddIconProps = {
    mode: number,
    onAddHandler: any,
    addBtnLeft: boolean,
}

const addIcon2 = ({ mode, onAddHandler, addBtnLeft}: AddIconProps) => {;
return <div className={!addBtnLeft ? "p-4 pt-2" : "p-4 pt-2"}>
<span
  className={
    mode === 1
      ? "drop-shadow-none fill-white/50"
      : "hover:drop-shadow-sm fill-[#C84B31] hover:fill-[#C84B31]/80"
  }
  onClick={onAddHandler}
>
  {svgIcons.addIcon}
</span>
</div>
};

export default addIcon2;