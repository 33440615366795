"use client";

import { type SetStateAction, useEffect, useState } from "react";
import AddIcon from "./components/addIcon";
import LoginBtn from "./components/loginBtn";
import AddIcon2 from "./components/addIcon2";
import LoginBtn2 from "./components/loginBtn2";
import Modal from "./modal";
import Changemodal from "./changemodal";
import Emptydatamodal from "./emptydatamodal";
import Heading from "./heading";
import FullPage from "./fullpage";
import BookList from "./booklist";
import WordList from "./wordlist";
import WordQuiz from "./wordquiz";
import Analytics from "./analytics";
import UserPage from "./userpage";
import SigninAccount from "./components/signinAccount";
import { svgIcons } from "./components/svgIcons";
import {
  postWord,
  postUser,
  signinUser,
  fetchWords,
} from "./utils/apiRequests";
import { tempServerWords } from "./components/tempData";
import {
  getLocalWords,
  getLocalPrefs,
  saveLocalWords,
  updateLocalPrefs,
  saveSampleLocalWords,
  clearLocalData,
  deleteFromLocal,
  saveToken,
} from "./utils/localStorage";
import { type Word, type Book } from "./utils/types";
import { getWordDJour } from "./components/wordoftheday";
import CreateAccount from "./components/createAccount";
import About from "./about";
import NewAccount from "./newAccount";
import NewCreateAccount from "./newCreateAccount";
import NewSigninAccount from "./newSigninAccount";
import Carousel from "./carousel";
import { validateInput, createLocalID } from "./utils/helpers";

const initialWordDJour = {
  localid: 0,
  sharedid: 1,
  user: "",
  userid: 0,
  book: "",
  author: "",
  word: "",
  page: "",
  date: "",
};

const tempWordDJour = {
  localid: 0,
  sharedid: 1,
  user: "",
  userid: 0,
  book: "Ulysses",
  author: "James Joyce",
  word: "stately",
  page: "1",
  date: "Fri Jun 16 1922 10:10:42 GMT (Greenwich Mean Time)",
};

// const URL = "http://localhost:3001";
const URL = "https://www.motscouture.com/api";

export const getTheDate: any = () => {
  return new Date().toISOString();
};

export const getOnlyDate: any = () => {
  return new Date().toISOString().substring(0, 10);
};

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(0);
  const [localWords, setLocalWords] = useState<Word[]>([]);
  const [sharedWords, setSharedWords] = useState<Word[]>([]);
  const [allWords, setAllWords] = useState<Word[]>([]);
  // const [gettingShared, setGettingShared] = useState<WordData[] | null>(null);
  const [wordDJour, setWordDJour] = useState<Word>(tempWordDJour);
  const [currentBook, setCurrentBook] = useState({ title: "", author: "" });
  const [books, setBooks] = useState<Book[]>([]);
  const [addBookMode, setAddBookMode] = useState(false);
  const [extrasMode, setExtrasMode] = useState(false);
  const [extrasType, setExtrasType] = useState("");
  const [shareToggle, setShareToggle] = useState(false);
  const [sharedToggle, setSharedToggle] = useState(false);
  const [lineSizePref, setLineSizePref] = useState(2);
  const [defToggle, setDefToggle] = useState(false);
  const [allowNotifPref, setAllowNotifPref] = useState(false);
  const [readingReminderPref, setReadingReminderPref] = useState(false);
  const [newWordAlertPref, setNewWordAlertPref] = useState(false);
  const [fromDJour, setFromDJour] = useState(false);
  const [fromAdd, setFromAdd] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [user, setUser] = useState("");
  const [userID, setUserID] = useState(0);
  const [signedIn, setSignedin] = useState(false);
  const [signinFailure, setSigninFailure] = useState(false);
  const [signupFailure, setSignupFailure] = useState(false);
  const [signinMode, setSigninMode] = useState(false);
  const [signupMode, setSignupMode] = useState(false);
  const [deluxeSignin, setDeluxeSignin] = useState(false);
  const [usernameText, setUsernameText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [isValid, setIsValid] = useState({ username: true, password: true });
  const [showAbout, setShowAbout] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [addBtnLeft, setAddBtnLeft] = useState(true);
  const [gameMode, setGameMode] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [newBookEntry, setNewBookEntry] = useState(false);
  const [newWordEntry, setNewWordEntry] = useState(false);

  // USE-EFFECTS

  // useEffect(() => {
  //   setNewWordEntry(prev => false);
  // }, [localWords]);

  useEffect(() => {
    setShowButton(() => false);
    setShowContent(() => false);
    setTimeout(() => {
      setShowButton(() => true);
    }, 500);
    setTimeout(() => {
      setShowContent(() => true);
    }, 1000);
  }, [signedIn]);

  useEffect(() => {
    setUsernameText(() => "");
    setPasswordText(() => "");
  }, [signupMode]);

  useEffect(() => {
    fetchSharedWords();
  }, []);

  // useEffect(() => {
  //   getWordDJourFromAllWords();
  // }, [sharedWords]);

  useEffect(() => {
    seedState();
  }, []);

  // useEffect(() => {
  //   getWordDJourFromAllWords();
  // }, [localWords]);

  const seedState = () => {
    setIsLoading(true);
    // const now = getTheDate();
    // const now = getOnlyDate();
    // updateLocalPrefs("lastUse", now);
    const prefs = getLocalPrefs();
    if (prefs.user === undefined) {
      updateLocalPrefs("user", "");
    }
    const ls = getLocalWords();
    const lp = getLocalPrefs();
    getUser();
    setLocalWords((prev) => ls);
    console.log("here - lw: ", localWords);
    // setIsLoading(false);
    if (ls.length === 0) {
      setEmptyData(() => true);
    }
    setShareToggle(lp.shareWords);
    setSharedToggle(lp.sharedWords);
    setLineSizePref(lp.lineSize);
    setDefToggle(lp.defPref);
  };

  useEffect(() => {
    if (sharedToggle === true) {
      setAllWords(() => mergeWords());
    } else {
      setAllWords(() => localWords);
    }
  }, [sharedToggle, sharedWords, localWords]);

  useEffect(() => {
    if (signupMode === true || signinMode === true) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  }, [signinMode, signupMode]);

  useEffect(() => {
    if (localWords.length > 0) {
      makeBookList();
    }
  }, [localWords]);

  useEffect(() => {
    setSigninMode(() => false);
    setSignupMode(() => false);
  }, [extrasType]);

  useEffect(() => {
    if (localWords.length > 0 && sharedWords.length > 0) {
      getWordDJourFromAllWords();
    }
  }, [localWords, sharedWords]);

  // useEffect(() => {
  //   // setAllWords(() => mergeWords());
  //   if (localWords.length > 0) {
  //     getWordDJourFromAllWords();
  //     console.log("allWords", allWords);
  //   } else {
  //     console.log("allWords", allWords);
  //   }
  // }, [localWords]);

  // useEffect(() => {
  //   const today = getOnlyDate();
  //   console.log('getOnlyDate is', today);
  //   const { lastUse } = getLocalPrefs();
  //   console.log('lastUse', lastUse);
  //   if (today === lastUse) {
  //     console.log('today === lastUse: no worddjour action');
  //     const now = getOnlyDate();
  //     updateLocalPrefs("lastUse", now);
  //     return;
  //   }
  //   if (localWords.length > 0) {
  //     console.log('getting wdj now');
  //     setIsLoading(true);
  //     console.log('allWords', allWords);
  //     const gotWord = getWordDJour(allWords);
  //     console.log('gotWord is', gotWord);
  //     const newWord = {
  //       localid: 0,
  //       sharedid: 0,
  //       user: "",
  //       userid: 0,
  //       date: gotWord.date,
  //       word: gotWord.word,
  //       book: gotWord.book,
  //       author: gotWord.author,
  //       page: gotWord.page,
  //       definition: gotWord.definition,
  //     };
  //     console.log('newWord is', newWord);
  //     // setWordDJour(prev => newWord);
  //     setIsLoading(false);
  //     console.log('finished with wdj:', wordDJour.word);
  //     const now = getOnlyDate();
  //     updateLocalPrefs("lastUse", now);
  //   }
  // }, [allWords]);

  // FUNCTIONS

  const fetchSharedWords = async () => {
    try {
      setIsLoading(() => true);
      await (await fetch(`${URL}/words`)).json().then((data) => {
        setSharedWords(() => data.data);
        console.log("getting wdj1");
      });
    } catch {
      setSharedWords(() => tempServerWords);
      // getWordDJourFromAllWords();
      console.log("error");
    }
    setIsLoading(() => false);
  };

  const getWordDJourFromAllWords = () => {
    setIsLoading(() => true);
    const today = getOnlyDate();
    console.log("getOnlyDate is", today);
    const { lastUse } = getLocalPrefs();
    const { wordOfDay } = getLocalPrefs();
    console.log("lastUse", lastUse);
    console.log("lastWordOfDay", wordOfDay);
    console.log("localWords", localWords);
    if (today === lastUse) {
      console.log("today === lastUse: no worddjour action");
      setWordDJour((prev) => wordOfDay);
      updateLocalPrefs("lastUse", today);
      setIsLoading(() => false);
      return;
    }
    if (localWords.length > 0) {
      console.log("getting wdj now2");
      setIsLoading(true);
      console.log("localWords", localWords);
      console.log("sharedWords", sharedWords);
      console.log("allWords", allWords);
      console.log("mergeWords", mergeWords());
      const gotWord = getWordDJour(mergeWords());
      console.log("gotWord is", gotWord);
      setWordDJour(() => gotWord);
      setIsLoading(false);
      console.log("finished with wdj:", wordDJour.word);
      updateLocalPrefs("lastUse", today);
      updateLocalPrefs("wordOfDay", gotWord);
      setIsLoading(() => false);
    }
  };

  const mergeWords = () => {
    let combinedWords = [...localWords];
    sharedWords.forEach((w) => {
      if (combinedWords.some((e) => e.word === w.word)) {
        return;
      } else {
        combinedWords.push(w);
      }
    });
    return combinedWords;
  };

  const makeBookList = () => {
    // console.log("Making booklist...");
    const bookList: Book[] = [];
    localWords.forEach((w) => {
      const newBook = { title: w.book, author: w.author };
      bookList.push(newBook);
    });
    const revisedBookList = bookList.filter((b) => b.title !== "");
    // console.log("bookList", revisedBookList);

    const removeDupTitles = (array: any, key: any) => {
      return revisedBookList.reduce((arr: any, item: any) => {
        const removed = arr.filter(
          (i: { [x: string]: any }) => i[key] !== item[key] && item[key]
        );
        return [...removed, item];
      }, []);
    };

    const filteredBookList = removeDupTitles(bookList, "title");
    // console.log("fb", filteredBookList);
    const cleanedList = filteredBookList.filter(
      (b: { title: string }) => b.title !== ""
    );
    // console.log("cl", cleanedList);
    setBooks(() => cleanedList);
  };

  const getLastEntryFromBook = (book: string) => {
    const reversedEntries = [...localWords].reverse();
    let result = reversedEntries.find((e) => e.book === book);
    console.log("result", result);
    if (result?.page === undefined || result.page === "start") {
      let newResult = {
        book: result?.book,
        author: result?.author,
        page: "just started",
      };
      return newResult;
    } else {
      return result;
    }
  };

  // HANDLERS

  const onAddHandler = () => {
    setMode(1);
  };

  const onChangeHander = () => {
    setMode(2);
  };

  const bookSelectionHandler = (bookName: String) => {
    console.log("good!");
    // setCurrentBook(bookName);
  };

  const readingReminderHandler = () => {
    setReadingReminderPref((prev) => !prev);
    updateLocalPrefs("readingPref", readingReminderPref);
    console.log("good!");
    // setCurrentBook(bookName);
  };

  const newWordAlertHandler = () => {
    setNewWordAlertPref((prev) => !prev);
    updateLocalPrefs("newwordPref", newWordAlertPref);
    // setCurrentBook(bookName);
  };
  const deletePrefsHandler = () => {
    console.log("good!");
    // setCurrentBook(bookName);
  };

  const deleteWordsHandler = () => {
    console.log("good!");
    // setCurrentBook(bookName);
  };

  const deleteAllHandler = () => {
    console.log("good!");
    // setCurrentBook(bookName);
  };

  const retrieveAllYourSharedWordsHandler = () => {
    console.log("good!");
    // setCurrentBook(bookName);
  };

  const closeModal = () => {
    // if (currentBook.title === "+ Add Book") {
    //   setCurrentBook({ title: "No Book, Just a Word", author: "" });
    // }
    setMode(0);
  };

  const addBookHandler = (b: Book) => {
    const bookIsValid = validateInput(b.title, "book");
    const authorIsValid = validateInput(b.author, "book");
    if (bookIsValid && authorIsValid) {
      const currentBookList = [...books];
      if (!currentBookList.every((t) => b.title.includes(t.title))) {
        currentBookList.push({ title: b.title, author: b.author });
      }
      console.log("currentBooklist", currentBookList);
      setBooks(() => currentBookList);
      setCurrentBook(b);
      console.log("setting...");
      const ls = getLocalWords();
      setLocalWords(() => ls);
      // const oldBooks = books;
      // const newBooks = books.push(b);
    }
  };

  const addWordHandler = (b: Book, w: string, p: string) => {
    // const { user, userid } = getLocalPrefs();
    if (b.title === "No Book, Just a Word") {
      b.title = "";
    }
    console.log(b, w, p);
    const newWordForPost = {
      localid: createLocalID(w),
      sharedid: 0,
      user: user,
      userid: userID,
      book: b.title,
      author: b.author,
      word: w,
      page: p,
      date: getTheDate(),
      shared: true,
    };
    console.log("data1", newWordForPost);
    saveLocalWords(newWordForPost);
    setCurrentBook(b);
    closeModal();
    const ls = getLocalWords();
    setLocalWords(() => ls);
    if (shareToggle === true) {
      postWord(newWordForPost);
    } else {
      console.log(`${newWordForPost.word} will not be shared.`);
    }
    console.log("first");
    setIsLoading(() => true);
    if (newWordForPost.word === "start") {
      setNewBookEntry(() => true);
      setTimeout(() => setNewBookEntry(() => false), 500);
    } else if (newWordForPost.word === "end") {
      console.log("nothing: end");
    } else {
      console.log("second");
      setNewWordEntry(() => true);
      setTimeout(() => setNewWordEntry(() => false), 500);
    }
    setTimeout(() => seedState(), 500);
    setIsLoading(() => false);
    if (defToggle === true) {
      setExtrasMode(true);
      setExtrasType("wordlist");
      setFromAdd(true);
    }
  };

  const deleteWord = (word: string) => {
    deleteFromLocal(word);
    setTimeout(() => seedState(), 500);
    setExtrasMode(false);
  };

  const shareToggleHandler = () => {
    if (!signedIn) {
      return;
    }
    setShareToggle((prevValue) => !prevValue);
    updateLocalPrefs("shareWord", !shareToggle);
    console.log("SHARETOGGLE changed to: ", !shareToggle);
  };

  const sharedToggleHandler = () => {
    // if (!signedIn) {
    //   return;
    // }
    setSharedToggle((prevValue) => !prevValue);
    updateLocalPrefs("sharedWords", !sharedToggle);
    console.log("SHARED TOGGLE changed to: ", !sharedToggle);
  };

  const defToggleHandler = () => {
    setDefToggle((prevValue) => !prevValue);
    updateLocalPrefs("defPref", !defToggle);
    console.log("DEF TOGGLE changed to: ", !defToggle);
  };

  const fullPageHandler = (e: string) => {
    console.log(e);
    setExtrasType(e);
    setExtrasMode(true);
  };

  const closeFullPage = () => {
    setExtrasMode(false);
    setGameMode(() => 0);
  };

  const djourHandler = () => {
    setExtrasMode(true);
    setExtrasType("wordlist");
    setFromDJour(true);
  };

  const resetFromDJour = () => {
    setFromDJour(false);
  };

  const setSampleData = () => {
    console.log("setting sample data...");
    saveSampleLocalWords();
    setEmptyData(false);
    seedState();
  };

  const clearAllData = () => {
    console.log("setting sample data...");
    clearLocalData();
    setEmptyData(false);
    seedState();
  };

  const getUser = () => {
    const prefs = getLocalPrefs();
    setUserID(() => prefs.userid);
    setUser(() => prefs.user);
    setUsernameText(() => prefs.user);
  };

  const signinHandler = () => {
    // const usernameValid = validateInput(usernameText, 'username');
    let currentUser;
    if (usernameText === "" && user !== "") {
      currentUser = user;
    } else {
      currentUser = usernameText;
    }
    const usernameValid = validateInput(currentUser, "username");
    const passwordValid = validateInput(passwordText, "password");
    console.log(usernameValid, currentUser);
    console.log(passwordValid, passwordText);
    const validationCheck = {
      username: usernameValid,
      password: passwordValid,
    };
    setIsValid((prev) => validationCheck);
    if (usernameValid && passwordValid) {
      const newSignin = {
        username: currentUser,
        password: passwordText,
      };
      const response = signinUser(newSignin, signedInState);
      setIsLoading(true);
      console.log(response);
    }
  };

  const signedInState = (username: string) => {
    if (username === undefined) {
      console.log("RETURN FAIL - CONTINGENCY");
      setSigninFailure(true);
      setIsLoading(false);
      return;
    }

    setUser(username);
    setSignedin(() => true);
    setSignupMode(() => false);
    setDeluxeSignin(() => false);
    setSigninMode(() => false);
    setExtrasMode(false);
    setExtrasType(() => "");
    setUsernameText(() => "");
    setPasswordText(() => "");
    setSigninFailure(false);
    setIsLoading(false);
  };

  const signoutHandler = () => {
    setSignedin(() => false);
    setUser(() => "");
    updateLocalPrefs("user", "");
    setSignupMode(() => false);
    setSigninMode(() => false);
    setExtrasMode(false);
    setExtrasType(() => "");
  };

  const createAccountHandler = () => {
    const usernameValid = validateInput(usernameText, "username");
    const passwordValid = validateInput(passwordText, "password");
    const validationCheck = {
      username: usernameValid,
      password: passwordValid,
    };
    setIsValid((prev) => validationCheck);
    if (usernameValid && passwordValid) {
      const newUser = {
        userid: 0,
        username: usernameText,
        email: "none",
        password: passwordText,
        joined: "",
      };
      postUser(newUser, signupFailureHandler);
      // setSignedin(() => false);
      // setSignupMode(() => false);
      // setSigninMode(() => true);
      // setDeluxeSignin(() => false);
      // setUser(() => usernameText);
      // updateLocalPrefs("user", usernameText);
      // setUsernameText(() => "");
      // setPasswordText(() => "");
    }
  };

  const signupFailureHandler = (result: boolean) => {
    if (result === true) {
      setSignedin(() => false);
      setSignupMode(() => false);
      setSigninMode(() => true);
      setDeluxeSignin(() => false);
      setUser(() => usernameText);
      updateLocalPrefs("user", usernameText);
      setUsernameText(() => usernameText);
      setPasswordText(() => "");
      setSignupFailure(() => false);
    } else {
      setSignupFailure(() => true);
    }
  };

  const handleUsernameText = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setUsernameText(e.target.value);
  };

  const handlePasswordText = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setPasswordText(e.target.value);
  };

  const signinModeHandler = (from: string) => {
    if (from === "deluxe") {
      setSigninMode(true);
      setDeluxeSignin(true);
      setSignupMode(false);
    } else if (from === "switch") {
      setSigninMode(false);
      setDeluxeSignin(false);
      setSignupMode(true);
    } else {
      setSigninMode(true);
      setDeluxeSignin(false);
      setSignupMode(false);
    }
  };

  const cancelSignin = (from: string) => {
    setUsernameText(() => "");
    setPasswordText(() => "");
    setSignupFailure(() => false);
    setSigninFailure(false);
    setIsLoading(false);
    setSigninMode(false);
    setDeluxeSignin(false);
    setSignupMode(false);
  };

  const cancelSignHandler = () => {
    setUsernameText(() => "");
    setPasswordText(() => "");
    setSignupFailure(() => false);
    setSigninFailure(() => false);
    setIsLoading(false);
    setSigninMode(false);
    setSignupMode(false);
    setDeluxeSignin(false);
    setSignupMode(false);
    setIsValid({ username: true, password: true });
  };

  const signupModeHandler = (from: any) => {
    if (from === "signin") {
      setSignupMode(true);
      setSigninMode(false);
    }
    setSignupMode(true);
    setSigninMode(false);
  };

  const signoutModeHandler = () => {
    setUser("");
    setSigninFailure(false);
    setSigninMode(false);
    setDeluxeSignin(false);
    setSignupMode(false);
    setSignedin(false);
  };

  const startGameHandler = (game: number) => {
    console.log("starting:", game);
    setGameMode(() => game);
  };

  const allowNotifHandler = () => {
    console.log("allow notifs?");
    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        console.log("granted");
        setAllowNotifPref((prev) => true);
        updateLocalPrefs("allowNotif", true);
        notification("welcome");
      } else {
        console.log("else");
        setAllowNotifPref((prev) => false);
      }
      console.log("notif done");
    });
  };

  const notification = (type: string) => {
    let title;
    let options;
    if (type === "welcome") {
      title = `Notifications Allowed & Working`;
      options = {
        body: `Thanks ${user}, you've subscriptions to notifications. I'm still working on the backend, and should hopefully have that ready & pushing soon.`,
        icon: `images/mc_icon_192.png`,
      };
    } else {
      title = `New ${type}.`;
      options = {
        body: `This notfication is about ${type}s.`,
        icon: `images/${type}_icon.png`,
      };
    }
    console.log("notif start");
    new Notification(title, options);
    console.log("notif end");
  };

  return (
    <>
      {showAbout && (
        <div>
          <About setShowAbout={setShowAbout} />
          <div
            style={{
              backgroundImage: `url('https://www.dalesmith.com/play/img/dots4.png')`,
            }}
            className="absolute top-0 left-0 h-screen w-full bg-black/40"
            onClick={() => setShowAbout(false)}
          ></div>
        </div>
      )}
      {emptyData && (
        <div>
          <Emptydatamodal
            setEmptyData={setEmptyData}
            setSampleData={setSampleData}
            clearAllData={clearAllData}
            data={allWords}
          />
          <div
            style={{
              backgroundImage: `url('https://www.dalesmith.com/play/img/dots4.png')`,
            }}
            className="absolute top-0 left-0 h-screen w-full bg-black/40"
            onClick={() => setEmptyData(false)}
          ></div>
        </div>
      )}
      {mode === 1 && (
        <Modal
          closeModal={closeModal}
          selectBook={bookSelectionHandler}
          books={books}
          currentBook={currentBook}
          setCurrentBook={setCurrentBook}
          addBookHandler={addBookHandler}
          addWordHandler={addWordHandler}
          setAddBookMode={setAddBookMode}
          addBookMode={addBookMode}
          shareToggle={shareToggle}
          shareToggleHandler={shareToggleHandler}
          defToggle={defToggle}
          defToggleHandler={defToggleHandler}
          signedIn={signedIn}
          toggleSetting={true}
        />
      )}
      {mode === 2 && (
        <Changemodal
          closeModal={closeModal}
          selectBook={bookSelectionHandler}
          books={books}
          currentBook={currentBook}
          setCurrentBook={setCurrentBook}
          addBookHandler={addBookHandler}
          addWordHandler={addWordHandler}
          setAddBookMode={setAddBookMode}
          addBookMode={addBookMode}
          shareToggleHandler={shareToggleHandler}
          shareToggle={shareToggle}
          signedIn={signedIn}
        />
      )}
      {extrasMode && (
        <FullPage
          closeFullPage={closeFullPage}
          extrasType={extrasType}
          addBookHandler={addBookHandler}
          addWordHandler={addWordHandler}
          signoutHandler={signoutHandler}
          user={user}
        >
          {extrasType === "booklist" && (
            <BookList
              data={localWords}
              getLastEntryFromBook={getLastEntryFromBook}
            />
          )}
          {extrasType === "wordlist" && (
            <WordList
              signedIn={signedIn}
              data={allWords}
              fromDJour={fromDJour}
              wordDJour={wordDJour}
              fromAdd={fromAdd}
              resetFromDJour={resetFromDJour}
              sharedToggleHandler={sharedToggleHandler}
              deleteWord={deleteWord}
              sharedToggle={sharedToggle}
              lineSizePref={lineSizePref}
            />
          )}
          {extrasType === "wordquiz" && (
            <WordQuiz
              data={allWords}
              user={user}
              startGameHandler={startGameHandler}
              gameMode={gameMode}
            />
          )}
          {extrasType === "analytics" && (
            <Analytics data={localWords} user={user} />
          )}
          {extrasType === "account" && (
            <UserPage
              data={allWords}
              user={user}
              readingReminderHandler={readingReminderHandler}
              newWordAlertHandler={newWordAlertHandler}
              allowNotifHandler={allowNotifHandler}
              allowNotifPref={allowNotifPref}
              readingReminderPref={readingReminderPref}
              newWordAlertPref={newWordAlertPref}
              deletePrefsHandler={deletePrefsHandler}
              deleteWordsHandler={deleteWordsHandler}
              deleteAllHandler={deleteAllHandler}
              retrieveAllYourSharedWordsHandler={
                retrieveAllYourSharedWordsHandler
              }
              addBtnLeft={addBtnLeft}
              setAddBtnLeft={setAddBtnLeft}
            />
          )}
        </FullPage>
      )}
      <div>
        <main className="p-4 pt-2 text-center md:max-w-xl md:m-auto">
          <Heading setShowAbout={setShowAbout} />
          <Carousel
            currentBook={currentBook}
            localWords={localWords}
            djourHandler={djourHandler}
            onChangeHander={onChangeHander}
            getLastEntryFromBook={getLastEntryFromBook}
            wordDJour={wordDJour}
            isLoading={isLoading}
          />
          {/* BOOKLIST */}

          {
            <div
              className={
                newBookEntry
                  ? "shadow-sm bg-white/80 border-4 border-[#C84B3180] flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full transition-all duration-5000"
                  : "shadow-sm bg-white/80 border-4 border-[#ffffff] flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full transition-all duration-500"
              }
              onClick={() => fullPageHandler("booklist")}
            >
              <div className="pr-2">
                <h2 className="text-lg">Book List</h2>
                <p className="text-base opacity-80">
                  All books, start & completion dates
                </p>
              </div>
              <button className="fill-[#C84B31]">
                {svgIcons.arrowForward}
              </button>
            </div>
          }

          {/* WORDLIST */}

          {
            <div
              className={
                newWordEntry
                  ? "shadow-sm bg-white/80 border-4 border-[#C84B3180] flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full transition-all duration-5000"
                  : "shadow-sm bg-white/80 border-4 border-[#ffffff] flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full transition-all duration-500"
              }
              onClick={() => fullPageHandler("wordlist")}
            >
              <div className="pr-2">
                <h2 className="text-lg">Word List</h2>
                <p className="text-base opacity-80">
                  All words, definitions & sources
                </p>
              </div>
              <button className="fill-[#C84B31]">
                {svgIcons.arrowForward}
              </button>
            </div>
          }

          {/* WORDQUIZ */}

          {
            <div
              className="shadow-sm bg-white/80 border-4 border-[#ffffff] flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full"
              onClick={() => fullPageHandler("wordquiz")}
            >
              <div className="pr-2">
                <h2 className="text-lg">Word Quiz</h2>
                <p className="text-base opacity-80">
                  Memorization & activity games
                </p>
              </div>
              {/* <button className="fill-[#C84B31]">{expandIcon}</button> */}
              <button className="fill-[#C84B31]">
                {svgIcons.arrowForward}
              </button>
            </div>
          }
          {
            <div
              className="shadow-sm bg-white/80 border-4 border-[#ffffff] flex items-center justify-between my-3 p-3 py-3 rounded-lg text-left w-full"
              onClick={() => fullPageHandler("analytics")}
            >
              <div className="pr-2">
                <h2 className="text-lg">Analytics</h2>
                <p className="text-base opacity-80">
                  Stats, trends, cadence & predictions.
                </p>
              </div>
              <button className="fill-[#C84B31]">
                {svgIcons.arrowForward}
              </button>
            </div>
          }

          {/* ACCOUNT: NOT SIGNED IN */}
          {(!signupMode || !signinMode) && !signedIn && (
            <div className="hidden shadow-sm bg-white/80 flex items-center justify-between my-3 p-3 py-2 pb-3 rounded-lg text-left w-full">
              <div className="w-full flex flex-col">
                {user === "" && !signupMode && !deluxeSignin && (
                  <h2 className="inline-block pr-2">{"Account"}</h2>
                )}
                {user === "" && (
                  <div className="pr-2.5">
                    {!signupMode && deluxeSignin && (
                      <h2>{"Account Sign In"}</h2>
                    )}
                    {signupMode && (
                      <h2 className="inline-block pr-2">
                        {"Create an Account"}
                      </h2>
                    )}
                    {signupMode && signinMode && (
                      <p className="text-xs opacity-80 leading-tight inline-block">
                        Enter a username & password
                      </p>
                    )}
                    {!signupMode && !signinMode && (
                      <p className="text-xs leading-tight">
                        Create an account to save and share words, lorem ipsum
                        value prop content, etc.
                      </p>
                    )}
                    {signupMode && (
                      <div className="opacity-80">
                        <p className="text-sm leading-tight my-2">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                        <p className="text-sm">• Value prop 1</p>
                        <p className="text-sm">• Value prop 2</p>
                        <p className="text-sm">• Value prop 3</p>
                        <p className="text-sm mb-1">• Value prop 4</p>
                        {/* <p className="text-xs">
                        Enter a username & password
                      </p> */}
                      </div>
                    )}
                  </div>
                )}
                {user !== "" && !signedIn && (
                  <div className="">
                    {!signinMode && (
                      <h2 className="capitalize">{`Hi, ${user}!`}</h2>
                    )}
                    {signinMode && (
                      <h2 className="inline-block pr-2 capitalize">{`Hi, ${user}!`}</h2>
                    )}
                    {signinMode && (
                      <p className="text-xs opacity-80 leading-tight inline-block">
                        Enter your password
                      </p>
                    )}
                    {!signupMode && !signinMode && (
                      <p className="text-xs leading-tight">
                        Sign in to access account features
                      </p>
                    )}
                    {signinFailure && (
                      <p className="text-left text-[14px] text-red-500">
                        Sign in failed. Incorrect password.
                      </p>
                    )}
                  </div>
                )}
                {signupMode && !signinMode && !signedIn && (
                  <CreateAccount
                    handleUsernameText={handleUsernameText}
                    usernameText={usernameText}
                    handlePasswordText={handlePasswordText}
                    passwordText={passwordText}
                    createAccountHandler={createAccountHandler}
                    setSigninMode={setSigninMode}
                    setSignupMode={setSignupMode}
                    signinModeHandler={signinModeHandler}
                    signupModeHandler={signupModeHandler}
                    signoutModeHandler={signoutModeHandler}
                    isValid={isValid}
                    isLoading={isLoading}
                  />
                )}
                {signinMode && !signedIn && (
                  <SigninAccount
                    handleUsernameText={handleUsernameText}
                    usernameText={usernameText}
                    handlePasswordText={handlePasswordText}
                    passwordText={passwordText}
                    signinHandler={signinHandler}
                    setSigninMode={setSigninMode}
                    signinModeHandler={signinModeHandler}
                    signupModeHandler={signupModeHandler}
                    signoutModeHandler={signoutModeHandler}
                    cancelSignin={cancelSignin}
                    deluxeSignin={deluxeSignin}
                    isValid={isValid}
                    isLoading={isLoading}
                    signinFailure={signinFailure}
                  />
                )}
              </div>
              {user === "" && !signupMode && !signinMode && !signedIn && (
                <div className="flex gap-3 flex-col w-full text-center items-center">
                  <button
                    className="w-3/4 md:w-1/2 rounded-full h-8 bg-[#2a4978] text-white text-sm px-1"
                    onClick={() => signupModeHandler("main")}
                  >
                    Create Account
                  </button>
                  <button
                    className="w-full md:w-1/2 text-sm opacity-80 underline hover:no-underline hover:opacity-60 align-center text-center"
                    onClick={() => signinModeHandler("deluxe")}
                  >
                    Sign In
                  </button>
                </div>
              )}
              {user !== "" && !signinMode && !signupMode && !signedIn && (
                <div className="flex gap-3 flex-col md:flex-row w-full text-center items-center">
                  <button
                    className="w-3/4 md:w-1/2 rounded-full h-8 bg-[#2a4978] text-white text-sm px-2"
                    onClick={() => signinModeHandler("main")}
                  >
                    Sign In
                  </button>
                  {/* <button
                    className="w-full md:w-1/2 text-sm opacity-80 underline hover:no-underline hover:opacity-60 align-center text-center text-sm"
                    onClick={() => signoutModeHandler()}
                  >
                    Not me
                  </button> */}
                </div>
              )}
            </div>
          )}

          {signedIn && (
            <div
              className="hidden shadow-sm bg-[#2a4978] text-white flex items-center justify-between my-4 p-3 rounded-lg text-left w-full"
              onClick={() => fullPageHandler("account")}
            >
              <div className="pr-2">
                {/* <h2>{`${user}'s Account`}</h2> */}
                <h2 className="capitalize">{`${user}`}</h2>
                <p className="text-xs leading-tight">
                  View status, updates, settings, lorem ipsum, etc..
                </p>
              </div>
              <button className="fill-white">{svgIcons.arrowForward}</button>
            </div>
          )}
          <div className={addBtnLeft ? "hidden flex justify-between flex-row-reverse m-auto md:flex" : "hiddenflex justify-between m-auto md:flex"}>
            {!signinMode && !signupMode && !showAbout && !emptyData && (
              <AddIcon2
                mode={mode}
                onAddHandler={onAddHandler}
                addBtnLeft={addBtnLeft}
              />
            )}
            {mode !== 1 && !showAbout && !emptyData && !signinMode && !signupMode && (
              <LoginBtn2
                mode={mode}
                user={user}
                setShowLogin={setShowLogin}
                signedIn={signedIn}
                setSigninMode={setSigninMode}
                setSignupMode={setSignupMode}
                fullPageHandler={fullPageHandler}
                addBtnLeft={addBtnLeft}
                showButton={showButton}
                showContent={showContent}
              />
            )}
          </div>
        </main>
        {!signinMode && !signupMode && !showAbout && !emptyData && (
          <AddIcon
            mode={mode}
            onAddHandler={onAddHandler}
            addBtnLeft={addBtnLeft}
          />
        )}
        {mode !== 1 && !showAbout && !emptyData && (
          <LoginBtn
            mode={mode}
            user={user}
            setShowLogin={setShowLogin}
            signedIn={signedIn}
            setSigninMode={setSigninMode}
            setSignupMode={setSignupMode}
            fullPageHandler={fullPageHandler}
            addBtnLeft={addBtnLeft}
            showButton={showButton}
            showContent={showContent}
          />
        )}
        <NewAccount
          showLogin={showLogin}
          signinMode={signinMode}
          signupMode={signupMode}
          setShowLogin={setShowLogin}
          cancelSignHandler={cancelSignHandler}
        >
          {signupMode && (
            <NewCreateAccount
              handleUsernameText={handleUsernameText}
              usernameText={usernameText}
              handlePasswordText={handlePasswordText}
              passwordText={passwordText}
              createAccountHandler={createAccountHandler}
              setSigninMode={setSigninMode}
              setSignupMode={setSignupMode}
              signinModeHandler={signinModeHandler}
              signupModeHandler={signupModeHandler}
              signoutModeHandler={signoutModeHandler}
              isValid={isValid}
              isLoading={isLoading}
              // createAccountFailure={createAccountFailure}
              signinMode={signinMode}
              signupMode={signupMode}
              signedIn={signedIn}
              user={user}
              cancelSignHandler={cancelSignHandler}
              signupFailure={signupFailure}
            />
          )}
          {signinMode && (
            <NewSigninAccount
              handleUsernameText={handleUsernameText}
              usernameText={usernameText}
              handlePasswordText={handlePasswordText}
              passwordText={passwordText}
              signinHandler={signinHandler}
              setSigninMode={setSigninMode}
              signinModeHandler={signinModeHandler}
              signupModeHandler={signupModeHandler}
              signoutModeHandler={signoutModeHandler}
              cancelSignin={cancelSignin}
              deluxeSignin={deluxeSignin}
              isValid={isValid}
              isLoading={isLoading}
              signinFailure={signinFailure}
              signinMode={signinMode}
              signupMode={signupMode}
              signedIn={signedIn}
              user={user}
              cancelSignHandler={cancelSignHandler}
            />
          )}
        </NewAccount>
      </div>
    </>
  );
};

export default Home;

// const createLocalID = (word: string): number => {
//   let randomNumber = Math.abs(Math.floor(Math.random() * 10000));
//   let randomNumberString = randomNumber.toString();
//   let stringID = [];
//   for (let i = 0; i < word.length / 2; i++) {
//     stringID.push(word[i].charCodeAt(0) - 97);
//   }
//   let wordNumber = stringID.join("");
//   let combinedString = wordNumber + randomNumberString;
//   let combinedNumber = parseInt(combinedString);
//   return combinedNumber;
// };

// const validateInput = (input: string, type: string) => {
//   input = input.trim();

//   if (input === "") {
//     return false;
//   }

//   if (input.indexOf(" ") >= 0) {
//     return false;
//   }

//   if (input.length < 8 && type === "password") {
//     return false;
//   }

//   return true;
// };

// useEffect(() => {
//   if (sharedToggle === true) {
//     setAllWords(() => mergeWords());
//     // const combinedWords = mergeWords();
//     // setAllWords(() => combinedWords);
//     // // add shared words
//     // console.log("let's add some shared words to the total word list!");
//     // let onlyLocalWords = [...localWords];
//     // console.log("onlyLocalWords", onlyLocalWords);
//     // let onlySharedWords = [...sharedWords];
//     // console.log("onlySharedWords", onlySharedWords);
//     // // const newWords = fetchWords();
//     // let combinedWords = [...localWords, ...sharedWords];
//     // console.log("combinedWords", combinedWords);
//   } else {
//     setAllWords(() => localWords);
//     // add shared words
//     // console.log(
//     //   "let's remove some shared words from the the total word list!"
//     // );
//     // const ls = getLocalWords();
//     // let existingWords = [...data];
//     // const newWords = fetchWords();
//     // existingWords = [...newWords];
//   }
// }, [sharedToggle, sharedWords, localWords]);
