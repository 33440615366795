// const tempBooks: string[] = [
//   "The Blue Hotel",
//   "The Open Boat",
//   "Madame Bovary",
//   "Dubliners",
// ];

// const tempDataORIG = [
//   {
//     book: "The American",
//     word: "lacrymose",
//     page: 201,
//     date: "010122",
//   },
//   { book: "The American", word: "flounces", page: 203, date: "010222" },
//   { book: "The American", word: "intaglio", page: 207, date: "010322" },
//   { book: "The American", word: "malheureux", page: 212, date: "010422" },
//   { book: "The American", word: "betimes", page: 215, date: "010522" },
// ];

export const tempWordDJour = {
  localid: 0,
    sharedid: 1,
    user: "",
    userid: 0,
    book: "Ulysses",
    author: "James Joyce",
    word: "stately",
    page: "1",
    date: "Fri Jun 16 1922 10:10:42 GMT (Greenwich Mean Time)",
}

export const blankWDJ = {
  localid: 0,
  sharedid: 1,
  user: "",
  userid: 0,
  book: "Ulysses",
  author: "James Joyce",
  word: "stately",
  page: "1",
  date: "Fri Jun 16 1922 10:10:42 GMT (Greenwich Mean Time)",
  shared: false
};

export const tempLocalWords = [
  {
    localID: 1,
    sharedID: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "start",
    page: "0",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
    shared: false
  },
  {
    localID: 1,
    sharedID: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "physiognomy",
    page: "2",
    date: "Mon Jun 05 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
    shared: false
  },
  {
    localID: 1,
    sharedID: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "homeopathy",
    page: "3",
    date: "Tue Jun 06 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
    shared: false
  },
  {
    localID: 1,
    sharedID: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "salubrity",
    page: "4",
    date: "Wed Jun 07 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
    shared: false
  },
  {
    localID: 1,
    sharedID: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "jocosity",
    page: "4",
    date: "Thu Jun 08 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
    shared: false
  },
  {
    localID: 1,
    sharedID: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "avidity",
    page: "4",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
    shared: false
  },
  {
    localID: 1,
    sharedID: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "capricious",
    page: "6",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
    shared: false
  },
];

export const tempServerWords = [
  {
    localid: 0,
    sharedid: 1,
    user: "",
    userid: 0,
    book: "Ulysses",
    author: "James Joyce",
    word: "stately",
    page: "1",
    date: "Fri Jun 16 1922 10:10:42 GMT (Greenwich Mean Time)",
  },
];

export const tempData = [
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "start",
    page: "0",
    date: "Fri Feb 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "physiognomy",
    page: "2",
    date: "Mon Jun 05 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "homeopathy",
    page: "3",
    date: "Tue Jun 06 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "salubrity",
    page: "4",
    date: "Wed Jun 07 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "jocosity",
    page: "4",
    date: "Thu Jun 08 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "avidity",
    page: "4",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "capricious",
    page: "6",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "droll",
    page: "6",
    date: "Fri Jun 02 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "porte-monnaie",
    page: "7",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "philological",
    page: "11",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "obeisances",
    page: "12",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "votive",
    page: "12",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "Palais Royal",
    page: "15",
    date: "Fri Jun 02 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "decoction",
    page: "17",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "invidious",
    page: "19",
    date: "Fri Jun 02 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "pertinacity",
    page: "19",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "rote",
    page: "21",
    date: "Fri Jun 02 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "caprice de prince",
    page: "24",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "Trouvelle",
    page: "24",
    date: "Fri Jun 02 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "inscrutable",
    page: "25",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "furbelows",
    page: "27",
    date: "Fri Jun 02 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "diffident",
    page: "27",
    date: "Fri Jun 03 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "importunate",
    page: "27",
    date: "Fri Jun 04 2023 10:10:12 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "sacred fire",
    page: "27",
    date: "Fri Jun 05 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "pikestaff",
    page: "39",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "fastidious",
    page: "40",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "sangfroid",
    page: "41",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "seraglios",
    page: "43",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "interlocutor",
    page: "43",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "propounded",
    page: "49",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "post-prandial",
    page: "49",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "demitasse",
    page: "49",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "trenchant",
    page: "50",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "epithet",
    page: "67",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "imprecation",
    page: "241",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "votary",
    page: "242",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "fatuous",
    page: "242",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "truculent",
    page: "243",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "betook",
    page: "244",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "perforce",
    page: "246",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "The American",
    author: "Henry James",
    word: "end",
    page: "374",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "start",
    page: "0",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "hobnail",
    page: "1",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "profundity",
    page: "2",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "squib",
    page: "2",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "decorously",
    page: "3",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "churlishness",
    page: "4",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "sexton",
    page: "5",
    date: "Fri Jun 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "Madame Bovary",
    author: "Gustave Flaubert",
    word: "angelus",
    page: "5",
    date: "Fri August 02 2022 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "War and Peace",
    author: "Leo Tolstoy",
    word: "start",
    page: "0",
    date: "Fri May 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "War and Peace",
    author: "Leo Tolstoy",
    word: "lorgnette",
    page: "13",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "War and Peace",
    author: "Leo Tolstoy",
    word: "mantilla",
    page: "39",
    date: "Fri Aug 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "",
    author: "",
    word: "fecundity",
    page: "",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
  {
    localid: 1,
    sharedid: 0,
    user: "",
    userid: 0,
    book: "",
    author: "",
    word: "stochastic",
    page: "",
    date: "Fri Jun 02 2023 10:10:42 GMT-0700 (Pacific Daylight Time)",
  },
];

export const tempDefinitions = [
  {
    word: "epithet",
    phonetic: "/ˈɛ.pɪ.θɛt/",
    phonetics: [
      {
        text: "/ˈɛ.pɪ.θɛt/",
        audio:
          "https://api.dictionaryapi.dev/media/pronunciations/en/epithet-us.mp3",
        sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60610119",
        license: {
          name: "BY-SA 4.0",
          url: "https://creativecommons.org/licenses/by-sa/4.0",
        },
      },
    ],
    meanings: [
      {
        partOfSpeech: "noun",
        definitions: [
          {
            definition: "A term used to characterize a person or thing.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition:
              "A term used as a descriptive substitute for the name or title of a person.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition:
              "One of many formulaic words or phrases used in the Iliad and Odyssey to characterize a person, a group of people, or a thing.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition: "An abusive or contemptuous word or phrase.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition:
              "A word in the scientific name of a taxon following the name of the genus or species. This applies only to formal names of plants, fungi and bacteria. In formal names of animals the corresponding term is the specific name.",
            synonyms: [],
            antonyms: [],
          },
        ],
        synonyms: ["cognomen"],
        antonyms: [],
      },
      {
        partOfSpeech: "verb",
        definitions: [
          {
            definition: "To term; to refer to as.",
            synonyms: [],
            antonyms: [],
            example: 'He was epitheted "the king of fools".',
          },
        ],
        synonyms: [],
        antonyms: [],
      },
    ],
    license: {
      name: "CC BY-SA 3.0",
      url: "https://creativecommons.org/licenses/by-sa/3.0",
    },
    sourceUrls: ["https://en.wiktionary.org/wiki/epithet"],
  },
];

const tempDef2 = [
  {
    word: "car",
    phonetic: "/kɑː/",
    phonetics: [
      {
        text: "/kɑː/",
        audio:
          "https://api.dictionaryapi.dev/media/pronunciations/en/car-uk.mp3",
        sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014179",
        license: {
          name: "BY 3.0 US",
          url: "https://creativecommons.org/licenses/by/3.0/us",
        },
      },
      {
        text: "/kɑɹ/",
        audio:
          "https://api.dictionaryapi.dev/media/pronunciations/en/car-us.mp3",
        sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424729",
        license: {
          name: "BY-SA 3.0",
          url: "https://creativecommons.org/licenses/by-sa/3.0",
        },
      },
    ],
    meanings: [
      {
        partOfSpeech: "noun",
        definitions: [
          {
            definition:
              "A wheeled vehicle that moves independently, with at least three wheels, powered mechanically, steered by a driver and mostly for personal transportation.",
            synonyms: [
              "auto",
              "automobile",
              "carriage",
              "motor",
              "motorcar",
              "vehicle",
            ],
            antonyms: [],
            example: "She drove her car to the mall.",
          },
          {
            definition:
              "A wheeled vehicle, drawn by a horse or other animal; a chariot.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition: "An unpowered unit in a railroad train.",
            synonyms: ["railcar", "wagon"],
            antonyms: [],
            example: "The conductor coupled the cars to the locomotive.",
          },
          {
            definition:
              "An individual vehicle, powered or unpowered, in a multiple unit.",
            synonyms: [],
            antonyms: [],
            example:
              "The 11:10 to London was operated by a 4-car diesel multiple unit.",
          },
          {
            definition:
              "A passenger-carrying unit in a subway or elevated train, whether powered or not.",
            synonyms: [],
            antonyms: [],
            example:
              "From the frontmost car of the subway, he filmed the progress through the tunnel.",
          },
          {
            definition:
              "A rough unit of quantity approximating the amount which would fill a railroad car.",
            synonyms: ["carload", "wagonload"],
            antonyms: [],
            example: "We ordered five hundred cars of gypsum.",
          },
          {
            definition:
              "The moving, load-carrying component of an elevator or other cable-drawn transport mechanism.",
            synonyms: [],
            antonyms: [],
            example:
              "Fix the car of the express elevator - the door is sticking.",
          },
          {
            definition:
              "The passenger-carrying portion of certain amusement park rides, such as Ferris wheels.",
            synonyms: ["carriage"],
            antonyms: [],
            example:
              "The most exciting part of riding a Ferris wheel is when your car goes over the top.",
          },
          {
            definition:
              "The part of an airship, such as a balloon or dirigible, which houses the passengers and control apparatus.",
            synonyms: ["basket", "gondola"],
            antonyms: [],
          },
          {
            definition: "A sliding fitting that runs along a track.",
            synonyms: [],
            antonyms: [],
          },
          {
            definition: "The aggregate of desirable characteristics of a car.",
            synonyms: [],
            antonyms: [],
            example: "Buy now! You can get more car for your money.",
          },
          {
            definition: "A floating perforated box for living fish.",
            synonyms: [],
            antonyms: [],
          },
        ],
        synonyms: [
          "auto",
          "automobile",
          "carriage",
          "motor",
          "motorcar",
          "vehicle",
          "basket",
          "gondola",
          "carload",
          "wagonload",
          "carriage",
          "railcar",
          "wagon",
        ],
        antonyms: [],
      },
    ],
    license: {
      name: "CC BY-SA 3.0",
      url: "https://creativecommons.org/licenses/by-sa/3.0",
    },
    sourceUrls: ["https://en.wiktionary.org/wiki/car"],
  },
  {
    word: "car",
    phonetic: "/kɑː/",
    phonetics: [
      {
        text: "/kɑː/",
        audio:
          "https://api.dictionaryapi.dev/media/pronunciations/en/car-uk.mp3",
        sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014179",
        license: {
          name: "BY 3.0 US",
          url: "https://creativecommons.org/licenses/by/3.0/us",
        },
      },
      {
        text: "/kɑɹ/",
        audio:
          "https://api.dictionaryapi.dev/media/pronunciations/en/car-us.mp3",
        sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424729",
        license: {
          name: "BY-SA 3.0",
          url: "https://creativecommons.org/licenses/by-sa/3.0",
        },
      },
    ],
    meanings: [
      {
        partOfSpeech: "noun",
        definitions: [{ definition: "A turn.", synonyms: [], antonyms: [] }],
        synonyms: [],
        antonyms: [],
      },
    ],
    license: {
      name: "CC BY-SA 3.0",
      url: "https://creativecommons.org/licenses/by-sa/3.0",
    },
    sourceUrls: ["https://en.wiktionary.org/wiki/car"],
  },
  {
    word: "car",
    phonetic: "/kɑː/",
    phonetics: [
      {
        text: "/kɑː/",
        audio:
          "https://api.dictionaryapi.dev/media/pronunciations/en/car-uk.mp3",
        sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014179",
        license: {
          name: "BY 3.0 US",
          url: "https://creativecommons.org/licenses/by/3.0/us",
        },
      },
      {
        text: "/kɑɹ/",
        audio:
          "https://api.dictionaryapi.dev/media/pronunciations/en/car-us.mp3",
        sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424729",
        license: {
          name: "BY-SA 3.0",
          url: "https://creativecommons.org/licenses/by-sa/3.0",
        },
      },
    ],
    meanings: [
      {
        partOfSpeech: "noun",
        definitions: [
          {
            definition:
              "The first part of a cons in LISP. The first element of a list",
            synonyms: [],
            antonyms: [],
          },
        ],
        synonyms: [],
        antonyms: ["cdr"],
      },
    ],
    license: {
      name: "CC BY-SA 3.0",
      url: "https://creativecommons.org/licenses/by-sa/3.0",
    },
    sourceUrls: ["https://en.wiktionary.org/wiki/car"],
  },
];

async function getDef(word: string) {
  // return tempDef[0].meanings[0].definitions[0].definition;

  try {
    const response = await fetch(
      `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
    );
    const wordRef = await response.json();
    // console.log(wordRef[0].meanings[0].definitions[0].definition);
    console.log(wordRef[0].meanings[0].definitions);
    // console.log(response);
    // return wordRef[0].meanings[0].definitions[0].definition;
    // return wordRef[0].meanings[0].definitions[0].definition;
    return wordRef[0].meanings[0].definitions;
  } catch (error) {
    console.log(error);
    return "did not work!";
  }

  const wordRaw = [
    {
      word: "anomalous",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/anomalous-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=685466",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Deviating from the normal; marked by incongruity or contradiction; aberrant or abnormal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of uncertain or unknown categorization; strange.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Having anomalies.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/anomalous"],
    },
  ];

  // const wordDef = JSON.parse(wordJson);
}
