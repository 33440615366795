import { useEffect, useState } from "react";
import { useSwipeable, LEFT, RIGHT, UP, DOWN, SwipeEventData } from 'react-swipeable';
// import Definition from "./definition";
import { Word } from "./utils/types";
import { svgIcons } from "./components/svgIcons";
import { updateLocalPrefs } from "./utils/localStorage";

type WordListTypes = {
  signedIn: boolean;
  data: Word[];
  fromDJour: boolean;
  fromAdd: boolean;
  wordDJour: Word;
  resetFromDJour: any;
  sharedToggleHandler: any;
  sharedToggle: boolean;
  lineSizePref: number;
  deleteWord: any;
}

type Definition = {
  definition: string;
};

type Meaning = {
  definitions: Definition[];
  partOfSpeech: string;
  map: any;
};

type WordDef = {
  meanings: Meaning[];
  // map: any;
};

type DefSet = {
  definition: string;
  partOfSpeech: string;
};

type DefArray = DefSet[];

const loadingDef = {
  meanings: [
    {
      definitions: [
        {
          definition: "Definitions are loading right now...",
        },
      ],
      partOfSpeech: "verb",
    },
  ],
};

const loadingProcessedDef: DefSet[] = [
  { definition: "Loading definitions...", partOfSpeech: "verb" },
];

const offlineDef: DefSet[] = [
  {
    definition: "Definitions are offline right now...",
    partOfSpeech: "adjective",
  },
];

const failDef: DefSet[] = [
  {
    definition: "No definition found.",
    partOfSpeech: "",
  },
];

const speechParts = ["noun", "verb", "adjective", "adverb"];

const translatePart = (part: string) => {
  switch (part) {
    case "noun":
      return ", n.";
      break;
    case "verb":
      return ", v.";
      break;
    case "adjective":
      return ", adj.";
      break;
    case "adverb":
      return ", adv.";
      break;
    default:
      return;
  }
};

const sortList = ["Newest", "Oldest", "A - Z", "Z - A", "Longest", "Shortest"];

const lineSizes = [
  "text-sm mt-2.5 divide-y divide-dashed",
  "text-base mt-2.5 divide-y divide-dashed",
  "text-lg mt-2.5 divide-y divide-dashed",
  "text-xl mt-2.5 divide-y divide-dashed",
  "text-2xl mt-2.5 divide-y divide-dashed",
  "text-3xl mt-2.5 divide-y divide-dashed",
  "text-4xl mt-2.5 divide-y divide-dashed",
];

const WordList = (props: WordListTypes) => {
  const [sortType, setSortType] = useState(sortList[2]);
  const [words, setWords] = useState<Word[]>([]);
  const [toggle, setToggle] = useState(false);
  const [lineSize, setLineSize] = useState(props.lineSizePref || 2);
  const [wordDetailMode, setWordDetailMode] = useState<Word | null>(null);
  const [wordDeleteMode, setWordDeleteMode] = useState(false);
  // const [definitions, setDefinitions] = useState<WordDef>(loadingDef);
  const [processedDefinitions, setProcessedDefinitions] =
    useState(loadingProcessedDef);
  const [whichPDef, setWhichPDef] = useState(0);

  // const [whichMean, setWhichMean] = useState(0);
  // const [whichDef, setWhichDef] = useState(0);
  const [prevEnd, setPrevEnd] = useState(false);
  const [nextEnd, setNextEnd] = useState(false);

  const handleSwiped = (eventData: SwipeEventData) => {
    console.log('eD', eventData);
    if (processedDefinitions.length > 1) {
      if (eventData.dir === LEFT) {
        console.log('You swiped left - next def');
        if (whichPDef === processedDefinitions.length - 1) {
          console.log('nothing - last one!');
        } else {
          setWhichPDef((prevValue) => prevValue + 1);
        }
      } else if (eventData.dir === RIGHT) {
        console.log('You swiped right - prev def');
        if (whichPDef === 0) {
          console.log('nothing - first one!');
        } else {
          setWhichPDef((prevValue) => prevValue - 1)
        }
      }
    }
  }

  const handlers = useSwipeable({
    onSwiped: handleSwiped,
    onTouchStartOrOnMouseDown: (({ event }) => console.log('event', event)),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  // useEffect(() => {
  //   setWordDetailMode(props.data[2]);
  // }, [props.fromAdd]);

  useEffect(() => {
    setToggle(() => props.sharedToggle);
  }, [props.sharedToggleHandler]);

  useEffect(() => {
    setWords(() => props.data);
    closeWordDetail();
    // if (props.fromAdd === true) {
    //   setWordDetailMode(props.data[4])
    // }
  }, [props.data]);

  // useEffect(() => {}, [props.data]);

  useEffect(() => {
    // console.log(sortType);
    const sortedWords = sortLogic(words, sortType);
    setWords(sortedWords);
  }, [sortType, props.data]);

  const sortChangeHandler = (e: { target: { value: string } }) => {
    // console.log(e.target.value);
    setSortType(e.target.value);
  };

  const clickWordHandler = (word: Word) => {
    setWordDetailMode(word);
    // console.log();
  };

  const fetchDefinition = (e: { target: { value: string } }) => {
    const word = e;
    // alert(`Definition for ${word} coming soon.`);
    console.log(`Definition for ${word} coming soon.`);
  };

  const sortLogic = (w: Word[], l: string) => {
    const list: Word[] = [...props.data];

    if (l === "A - Z") {
      list.sort((a: { word: string }, b: { word: string }) =>
        a.word.localeCompare(b.word)
      );
    }

    if (l === "Z - A") {
      list.sort((a: { word: string }, b: { word: string }) =>
        b.word.localeCompare(a.word)
      );
    }

    if (l === "Newest") {
      list.sort((a, b) => +new Date(b.date) - +new Date(a.date));
    }

    if (l === "Oldest") {
      list.sort((a, b) => +new Date(a.date) - +new Date(b.date));
    }

    if (l === "Longest") {
      list.sort(
        (a: { word: string }, b: { word: string }) =>
          b.word.length - a.word.length
      );
    }

    if (l === "Shortest") {
      list.sort(
        (a: { word: string }, b: { word: string }) =>
          a.word.length - b.word.length
      );
    }

    return list;
  };

  const adjustSizeBigger = () => {
    const currentSize = lineSize;
    let newSize: number;
    if (currentSize === lineSizes.length-1) {
      newSize = lineSizes.length-1;
    } else {
      newSize = currentSize + 1;
    }
    setLineSize(() => newSize);
    updateLocalPrefs('lineSize', newSize);
  };

  const adjustSizeSmaller = () => {
    const currentSize = lineSize;
    let newSize: number;
    if (currentSize === 0) {
      newSize = 0;
    } else {
      newSize = currentSize - 1;
    }
    setLineSize(() => newSize);
    updateLocalPrefs('lineSize', newSize);
  };

  const wordDeleteHandler = (word: string) => {
    props.deleteWord(word);
    closeWordDetail();
  };

  const closeWordDetail = () => {
    setWordDeleteMode(false);
    setWordDetailMode(null);
    setWhichPDef(() => 0);
    props.resetFromDJour(() => false);
  };

  useEffect(() => {
    if (props.fromDJour === true) {
      setWordDetailMode(props.wordDJour);
    }
    // if (props.fromAdd) {
    //   setWordDetailMode(props.data[props.data.length-1])
    // }
  }, [closeWordDetail]);

  useEffect(() => {
    if (wordDetailMode) {
      console.log("requesting defintion for:", wordDetailMode.word);
      fetchDef(wordDetailMode.word);
      setWhichPDef(() => 0);
    }
  }, [wordDetailMode]);

  const fetchDef = (word: string) => {
    // setProcessedDefinitions(() => offlineDef);

    try {
      fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("response is:", data[0]);
          if (data[0] === undefined) {
            setProcessedDefinitions(() => failDef);
          } else {
            const pD = processDef(data[0]);
            setProcessedDefinitions(() => pD);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const processDef = (rawData: WordDef) => {
    // console.log('rawData', rawData);
    const defArray: DefArray = [];
    // const newData = {...rawData};
    rawData.meanings.map((meaning: Meaning) => {
      let pos = meaning.partOfSpeech;
      meaning.definitions.map((definition: Definition) => {
        const newSet: DefSet = {
          definition: definition.definition,
          partOfSpeech: pos,
        };
        defArray.push(newSet);
      });
    });
    console.log(defArray);
    return defArray;
  };

  return (
    <>
      <div
        className={`bg-[#191919] absolute z-1000 p-4 translate-y-0 left-0 w-full bottom-0 text-white rounded-t-lg md:rounded md:opacity-0 transition-all duration-300 ${
          wordDetailMode
            ? "translate-y-0 md:translate-y-0 md:opacity-100"
            : "translate-y-full md:translate-y-0 md:opacity-0"
        }`}
      >
        {/* WORD DETAIL MODE */}

        <div className="grid gap-1 grid-cols-1 pb-8 md:pb-4" {...handlers}>
        {props.wordDJour.word === wordDetailMode?.word  && <p className="text-sm opacity-65">Word D' Jour</p>}
          <div className="flex items-center justify-between">
            <div className="flex items-baseline">
              <p className="text-xl lowercase">{wordDetailMode?.word}</p>
              {/* {!typeof partOfSpeech && (
                <p className="text-sm opacity-80">{` ${translatePart(
                  partOfSpeech
                )}`}</p>
              )} */}
              {processedDefinitions && processedDefinitions[whichPDef].partOfSpeech !== "" && (
                <p className="text-lg opacity-80">{` ${translatePart(
                  processedDefinitions[whichPDef].partOfSpeech
                )}`}</p>
              )}
            </div>
            {processedDefinitions.length > 1 && (
              <div className="flex flex-row align-middle items-middle md:visible text-lg">
                {whichPDef === 0 ? (
                  <div className="fill-white opacity-20">
                    {svgIcons.defArrowBack}
                  </div>
                ) : (
                  <div
                    className={"fill-white opacity-100"}
                    onClick={() => setWhichPDef((prevValue) => prevValue - 1)}
                  >
                    {svgIcons.defArrowBack}
                  </div>
                )}
                <div>
                  <p className="px-2">{`${whichPDef + 1}/${
                    processedDefinitions.length
                  }`}</p>
                </div>
                {whichPDef === processedDefinitions.length - 1 ? (
                  <div className="fill-white opacity-20 pl-1">
                    {svgIcons.defArrowForward}
                  </div>
                ) : (
                  <div
                    className="fill-white opacity-100 pl-1"
                    onClick={() => setWhichPDef((prevValue) => prevValue + 1)}
                  >
                    {svgIcons.defArrowForward}
                  </div>
                )}
              </div>
            )}
          </div>
          {wordDetailMode?.book && <p className="text-sm">{`from ${wordDetailMode?.book}, p. ${wordDetailMode?.page}`}</p>}
          <p className="text-baseline leading-tight text-white opacity-80 h-[120px] mt-2 border-t border-white/20 pt-3 md:h-[80px]">
            {processedDefinitions[whichPDef]
              ? processedDefinitions[whichPDef].definition
              : "loading definition..."}
          </p>
          {/* {!wordDetailMode?.book && <p className="text-sm text-black">.</p>} */}
          {/* WORD DELETE MODE */}

          {wordDeleteMode && (
            <div className="flex gap-4 mt-4">
              <button
                className="w-3/4 text-center rounded-full h-12 text-lg bg-[#ff000095] tracking-wide md:w-2/3"
                onClick={() => wordDeleteHandler(wordDetailMode!.word)}
              >
                Confirm Delete Word
              </button>
              <button
                className="w-1/4 h-12 text-lg bg-none rounded-full border-solid border-2 border-[#ffffff80] md:w-1/3"
                onClick={() => closeWordDetail()}
              >
                Cancel
              </button>
            </div>
          )}
          {!wordDeleteMode && (
            <div className="flex gap-3 mt-4 justify-between">
              <button
                className="w-1/4 h-12 text-lg rounded-full bg-[#ff000095] md:w-1/3"
                onClick={() => setWordDeleteMode(true)}
              >
                Delete
              </button>
              <button
                className="w-3/4 h-12 text-lg bg-none rounded-full border-solid border-2 border-[#ffffff80] md:w-2/3"
                onClick={() => closeWordDetail()}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-0">
        <div className="relative">
          <select
            className="block appearance-none w-full text-gray-700 py-3 pr-8 leading-tight bg-white focus:outline-none focus:bg-white focus:border-gray-500 border border-gray-400 border-t-0 border-l-0 border-r-0 text-lg"
            onChange={sortChangeHandler}
          >
            <option>Sort by: {sortType}</option>
            {sortList.map((s) => {
              if (s !== sortType) return <option key={s}>{s}</option>;
            })}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-black h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <ul className={lineSizes[lineSize]}>
          <li className="flex justify-between align-middle">
            <div className="flex items-center justify-start w-full mb-2">
              <label
                htmlFor="sharedToggle"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    // disabled={props.signedIn === false}
                    id="sharedToggle"
                    type="checkbox"
                    className="sr-only"
                    onChange={props.sharedToggleHandler}
                    // checked={!props.signedIn ? false : props.sharedToggle}
                    checked={props.sharedToggle}
                  />
                  <div className="w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></div>
                  <div className="pl-2 dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                </div>
                <div className="ml-3 font-base text-base">
                  {!props.signedIn
                    ? `Include community words`
                    : `Include community words`}
                </div>
              </label>
            </div>
            <div
              className={
                lineSize !== props.lineSizePref
                  ? "flex gap-3 mb-2 p-1 rounded-full align-middle border-dashed border border-[#2a4978]"
                  : "flex gap-3 mb-2 p-1 rounded-full align-middle border-dashed border border-[#ffffff]"
              }
            >
              <div
                className={lineSize === 0 ? "opacity-20" : "opacity-60"}
                onClick={adjustSizeSmaller}
              >
                {svgIcons.subtractSizeIcon}
              </div>
              <p className="opacity-50 text-base">|</p>
              <div
                className={lineSize === lineSizes.length-1 ? "opacity-20" : "opacity-60"}
                onClick={adjustSizeBigger}
              >
                {svgIcons.addSizeIcon}
              </div>
            </div>
          </li>
          {words.map((w: Word) => {
            let shared = false;
            if (w.word === "start" || w.word === "end") {
              return;
            }
            if (w.sharedid > 0) {
              shared = true;
            }
            return (
              <li
                id={w.word}
                className={
                  !shared
                    ? "pb-1 hover:bg-white py-1 lowercase"
                    : "text-[#2a4978] pb-1 hover:bg-white py-1 lowercase"
                }
                key={w.book + w.word + w.page + w.date}
                onClick={() => clickWordHandler(w)}
              >
                <p>{w.word}</p>
                {/* {w.word === props.wordDJour.word && <p className="text-sm opacity-65">Word D'Jour</p>} */}
                {/* <p>{`${w.book}, p. ${w.page}`}</p> */}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default WordList;
